import React from 'react'
import { Doughnut } from 'react-chartjs-2';

function ColumanChat({data}) {
    return (
        <div className='donought-graph-report'>
            <Doughnut data={data} />
        </div>
    )
}

export default ColumanChat
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PrivateAxios } from '../../environment/AxiosInstance'
import Loader from '../landing/loder/Loader';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../environment/ToastMessage';

import { UserAuth } from '../auth/Auth';


function Department() {
    const { Logout, userDetails, MatchPermission } = UserAuth();
    const [loading, setLoading] = useState(false);
    const [department, setDepartment] = useState([]);
    const [update, setUpdate] = useState(false)
    const [create, setCreate] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [departmentValue, setDepartmentValue] = useState('')
    const [departmentInputValue, setDepartmentInputValue] = useState({
        "title": "",
        "status": ""
    })
    const departmentData = () => {
        setLoading(true)
        PrivateAxios.get('all-department')
            .then((res) => {
                setLoading(false)
                setDepartment(res.data.data);
            }).catch((err) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        departmentData();
    }, [])

    const departmentUpdateModelClose = () => {
        setUpdate(false);
        setDepartmentValue('')
    }
    const departmentCreateModelClose = () => {
        setCreate(false)
        setDepartmentInputValue('')
    }
    const deleteModalClose = () => {
        setDeleteShow(false);
        setDeleteId('')
    }

    const createDepartment = () => {
        setLoading(true)
        if (departmentInputValue.title == '') {
            WaringMessage('Department name can not be empty !')
            setLoading(false)
            return;
        }
        PrivateAxios.post("create-department", departmentInputValue)
            .then((res) => {
                departmentData();
                setLoading(false)
                SuccessMessage(res.data.data);
                departmentCreateModelClose();
                setDepartmentInputValue({ ...departmentInputValue, title: "" });
            }).catch((err) => {
                setLoading(false)
                ErrorMessage(err?.response?.data?.msg)
                if (err?.response?.status == 401) {
                    Logout();
                }
            })
    }
    const UpdateDepartment = () => {
        setLoading(true)
        PrivateAxios.put(`update-department/${departmentValue.id}`, departmentValue)
            .then((res) => {
                const updatedDepartment = department.map((item) =>
                    item.id === departmentValue.id ? { ...item, title: departmentValue.title, status: departmentValue.status } : item
                );
                setDepartment(updatedDepartment)
                setLoading(false)
                SuccessMessage(res.data.data);
                departmentUpdateModelClose();
            }).catch((err) => {
                setLoading(false)
                ErrorMessage(err.response.data.msg)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const deleteDepartment = () => {
        setLoading(true)
        PrivateAxios.delete(`delete-department/${deleteId}`)
            .then((res) => {
                const deleteDepartment = department.filter((item) => item.id != deleteId);
                setDepartment(deleteDepartment)
                setLoading(false)
                SuccessMessage(res.data.data);
                deleteModalClose();
            }).catch((res) => {
                setLoading(false)
            })
    }

    const statusChange = (data, e) => {
        const newStatus = e.target.checked ? 1 : 0;
        setLoading(true)
        PrivateAxios.put(`update-department/${data.id}`, { "title": data.title, "status": e.target.checked })
            .then((res) => {
                const updatedDepartment = department.map((item) =>
                    item.id === data.id ? { ...item, status: newStatus } : item
                );
                setDepartment(updatedDepartment)
                setLoading(false)
                SuccessMessage(res.data.data);
                departmentUpdateModelClose();
            }).catch((res) => {
                setLoading(false)
            })
    }


    return (
        <>
            {loading ? <Loader /> :
                < div className='p-4'>
                    <div className='w-100 d-flex justify-content-end mb-3'>
                        <button onClick={() => setCreate(true)} className='btn btn-exp-green'>
                            <i className='bi bi-plus-circle me-2'></i>
                            New
                        </button>
                    </div>
                    <div className='card w-100 overflow-hidden'>
                        {/* <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                            <div className='table-button-group mb-3'>
                                <button className='btn table-export-btn'  >
                                    <img src={process.env.PUBLIC_URL + 'assets/images/file-csv.svg'} alt="icon" />
                                </button>
                                <button className='btn table-export-btn'>
                                    <img src={process.env.PUBLIC_URL + 'assets/images/file-pdf.svg'} alt="icon" />
                                </button>
                                <button className='btn table-export-btn' >
                                    <img src={process.env.PUBLIC_URL + 'assets/images/print.svg'} alt="icon" />
                                </button>
                            </div>
                            <div className='d-flex align-items-center mb-3 ms-auto'>
                                <label className='mr-2 mb-0'>Search: </label>
                                <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                            </div>
                        </div> */}
                        <div className='card-body p-0'>
                            <table class="table table-striped border-top">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: 100 }}>Sl. No.</th>
                                        <th scope="col">Department Name</th>
                                        {/* <th scope="col">Status</th> */}
                                        <th scope="col" style={{ width: 150 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {department.map((data, i) => (
                                        <tr>
                                            <td scope="row" style={{ width: 100 }}>{i + 1}</td>
                                            <td>{data.title}</td>
                                            {/* <td>
                                            
                                                <label className="custom-switch" >
                                                    <input type="checkbox" checked={data.status == 1} onChange={(e) => statusChange(data, e)} />
                                                    <div className="switch-slider switch-round" />
                                                </label> */}

                                            {/* {
                                            data.status == 1 ? <span className='badge bg-success'>Active</span> : <span className='badge bg-danger'>Inactive</span>
                                        } */}
                                            {/* </td> */}
                                            <td style={{ width: 150 }}>
                                                <div className="d-flex">
                                                    {data.company_id != 0 || userDetails.position == "Owner" ?
                                                        <>
                                                            {MatchPermission(["Edit Department"]) ?
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Edit
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button onClick={() => { setDepartmentValue(data); setUpdate(true) }} to="#" className="me-1 table-action-btn">
                                                                        <i className='fi fi-rr-pencil'></i>
                                                                    </button>
                                                                </OverlayTrigger> : ""
                                                            }
                                                            {MatchPermission(["Delete Department"]) ?
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Delete
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button onClick={() => { setDeleteShow(true); setDeleteId(data.id) }} className="me-1 table-action-btn" >
                                                                        <i className='fi fi-rr-trash text-exp-red'></i>
                                                                    </button>
                                                                </OverlayTrigger> : ""
                                                            }
                                                        </> : ""
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div >
            }

            {/* create Department */}
            <Modal show={create} onHide={departmentCreateModelClose} centered backdrop="static">
                <Modal.Header closeButton className='gth-blue-light-bg'>
                    <Modal.Title className='gth-modal-title'>Create Department</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-1'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Title</label>
                            <input type='text' className={`form-control`} placeholder='Enter title' onChange={(e) => setDepartmentInputValue({ ...departmentInputValue, title: e.target.value })} />
                        </div>
                    </div>
                    {/* <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Status</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setDepartmentInputValue({ ...departmentInputValue, status: e.target.value })}>
                                <option value="1" selected>Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='btn btn-secondary' onClick={departmentCreateModelClose}>
                        Close
                    </button> */}
                    <button className='btn btn-exp-green' onClick={createDepartment}>
                        Create
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Update department */}
            <Modal show={update} onHide={departmentUpdateModelClose} centered backdrop="static">
                <Modal.Header closeButton className='gth-blue-light-bg'>
                    <Modal.Title className='gth-modal-title'>Update Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Title</label>
                            <input type='text' value={departmentValue.title} className={`form-control`} name='task_name' placeholder='Enter title' onChange={(e) => setDepartmentValue({ ...departmentValue, title: e.target.value })} />
                        </div>
                    </div>
                    {/* <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Status</label>
                            <Form.Select aria-label="Default select example" value={departmentValue.status} onChange={(e) => setDepartmentValue({ ...departmentValue, status: e.target.value })}>
                                <option value="1" >Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='btn-sm' onClick={departmentUpdateModelClose}>
                        Close
                    </button> */}
                    <button className='btn btn-exp-green' onClick={UpdateDepartment}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Delete department */}
            <Modal
                show={deleteShow}
                onHide={deleteModalClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton className="gth-light-red-bg">
                    <Modal.Title className="gth-text-danger">Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-confirm-wrap text-center">
                        <div className="delete-confirm-icon mb-3 mt-2">
                            <img src={process.env.PUBLIC_URL + '/assets/images/delete-warning.svg'} alt="Warning" className="img-fluid" />
                        </div>
                        <h4 className="text-muted">Are you sure?</h4>
                        <p className="text-muted">
                            Do you really want to delete these record?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button className='btn btn-secondary' onClick={deleteModalClose}>
                        Cancel
                    </button>
                    <button className='btn btn-exp-red' onClick={deleteDepartment}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Department
import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserAuth } from '../auth/Auth';
import { PrivateAxios, url } from '../../environment/AxiosInstance';
import moment from 'moment';
import { TaskMode, TaskPriority } from '../../utils/StaticData';
import CheckSheetDetails from '../taskManager/checksheet/CheckSheetDetails';
import { SuccessMessage } from '../../environment/ToastMessage';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import Select from 'react-select';



function AuditorChecksheet() {
    const { Logout, User } = UserAuth();
    const [activeTab, setActiveTab] = useState('systemTaskTodo');
    const [assignByMeTableData, setAssignByMeTableData] = useState([]);
    const [assignByMeTableAllData, setAssignByMeTableAllData] = useState([]);
    const GetToDOTask = () => {
        PrivateAxios.get('checksheet/audit-todo')
            .then((res) => {
                setAssignByMeTableAllData(res.data.data)
                setAssignByMeTableData(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetCompleteTask = () => {
        PrivateAxios.get('checksheet/audit-complete')
            .then((res) => {
                setAssignByMeTableAllData(res.data.data)
                setAssignByMeTableData(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    useEffect(() => {
        GetToDOTask();
    }, []);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        if (tabId == "systemTaskTodo") {
            GetToDOTask();
        }
        if (tabId == "systemTaskCompleted") {
            GetCompleteTask()
        }
    };
    const getUser = (id) => {
        var name = User.find(data => data.id == id).name;
        return name;
    }
    const getTaskMode = (id) => {
        var mode = TaskMode.find(data => data.id == id).title;
        return mode;
    }
    //===================Details======================
    const [taskInfoShow, setTaskInfoShow] = useState(false);
    const [taskInfoValue, setTaskInfoValue] = useState('');
    const TaskInfoView = (e) => {
        setTaskInfoShow(true);
        setTaskInfoValue(e)
    }
    const TaskInfoHide = () => {
        setTaskInfoShow(false);
        setTaskInfoValue('');
    }


    const assignByMeColumns = [
        {
            name: "Name",
            selector: (row) => row.checkSheetList.name,
            sortable: true,
            width: "240px",
            cell: (row) => (
                <>
                    <button className="table-data-change-btn" onClick={() => TaskInfoView(row)} >
                        <div className="project-name text-truncate">
                            {/* onClick={() => DetailsModalShow(row.checkSheetList)} */}
                            <h6 className="text-truncate gap-2">{row.checkSheetList && row.checkSheetList.name} </h6>
                        </div>
                    </button>

                    {row.checkSheetList.subtask && row.checkSheetList.subtask.length > 0 ?
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="auto"
                            overlay={
                                <Popover id="statusChange" className="status-wrap">
                                    <div className="px-3 py-2">
                                        {
                                            row.checkSheetList && row.checkSheetList.subtask.map((item) => (
                                                <ul className="subtask-ul">
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))
                                        }
                                    </div>
                                </Popover>
                            }
                        >
                            <button className="link-btn ms-2" >
                                <span className="text-exp-blue fw-bold" ><i class="fi fi-sr-info"></i></span>
                            </button>
                        </OverlayTrigger> : ""
                    }

                </>

            ),
        },
        {
            name: "Assignee",
            selector: (row) => row.checkSheetList.assigned_by != 0 ? getUser(row.checkSheetList.assigned_by) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {User && User.map((item) => (
                        item.id == row.checkSheetList.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        // {
        //     name: "Mode",
        //     selector: (row) => row.checkSheetList && getTaskMode(row.checkSheetList.task_mode_id),
        //     sortable: true,
        //     width: "220px",
        //     cell: (row) => (
        //         <div className='w-100 h-100'>
        //             <button className="table-data-change-btn text-nowrap">
        //                 {TaskMode && TaskMode.find(data => data.id == row.checkSheetList.task_mode_id) && TaskMode.find(data => data.id == row.checkSheetList.task_mode_id).title}
        //             </button>
        //         </div>

        //     ),
        // },
        {
            name: "Issue Date",
            selector: (row) => row.completed_date ? moment(row.completed_date).format('DD-MM-YYYY | hh:mm A') : '',
            sortable: true,
            reorder: true,
            width: "190px"
        },
        {
            name: "Complete Date",
            selector: (row) => row.audit_completed_date ? moment(row.audit_completed_date).format('DD-MM-YYYY | hh:mm A') : '',
            sortable: true,
            reorder: true,
            width: "190px",
        },
        {
            name: "Mode",
            selector: (row) => row.checkSheetList && getTaskMode(row.checkSheetList.task_mode_id),
            sortable: true,
            width: "150px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {TaskMode.find(data => data.id == row.checkSheetList.task_mode_id).title}
                    </button>
                </div>

            ),
        },
        {
            name: "Priority",
            selector: (row) => row.checkSheetList.task_priority_id == 1 ? "Urgent" : row.checkSheetList.task_priority_id == 2 ? "High" : row.checkSheetList.task_priority_id == 3 ? "Normal" : row.checkSheetList.task_priority_id == 4 ? "Low" : "",
            sortable: true,
            width: "130px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {row && row.checkSheetList && row.checkSheetList.task_priority_id != '' ? row.checkSheetList.task_priority_id == 1 ?
                            <div className="priority-set urgent">
                                <i className="fi fi-sr-flag-alt mr-2"></i>
                                <span>Urgent</span>
                            </div> :
                            row.checkSheetList.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                                <i className="fi fi-sr-flag-alt mr-2"></i>
                                <span>High</span>
                            </div> : row.checkSheetList.task_priority_id == 3 ?
                                <div className="priority-set normal priority-list-item" >
                                    <i className="fi fi-sr-flag-alt mr-2"></i>
                                    <span>Normal</span>
                                </div> : row.checkSheetList.task_priority_id == 4 ? <div className="priority-set low priority-list-item" >
                                    <i className="fi fi-rr-stop-circle mr-2"></i>
                                    <span>Low</span>
                                </div> : "" : ""
                        }
                    </button>
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) => row.status == 1 ? "To Do" : row.status == 2 ? "In Progress" : row.status == 3 ? "Done" : row.status == 4 ? "Close" : "",
            sortable: true,
            width: "120px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {row.status == 1 ?
                            <div className="status-item todo status-list-item">
                                <i className="fi fi-rr-dot-circle me-2"></i>
                                <span>To Do</span>
                            </div> : row.status == 2 ?
                                <div div className="status-item inprogress">
                                    <i className="fi fi-rr-process me-2"></i>
                                    <span>In Progress</span>
                                </div> : row.status == 3 ?
                                    <div className="status-item completed status-list-item">
                                        <i className="fi fi-rs-check-circle me-2"></i>
                                        <span>Completed</span>
                                    </div> : row.status == 4 ?
                                        <div className="status-item status-list-item">
                                            <i className="fi fi-rr-stop-circle me-2"></i>
                                            <span>Close</span>
                                        </div> : ""
                        }
                    </button>
                </div>


            ),
        },
        {
            name: "Action",
            width: "210px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">
                    {activeTab == "systemTaskTodo" ?
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Done
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => taskDoneModalShow(row)}>
                                    <i class="fi fi-rr-check"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Re-Open
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => taskReOpenModalShow(row)}>
                                    <i class="fi fi-rr-restock"></i>
                                </button>
                            </OverlayTrigger>
                        </> : ""
                    }
                </div>
            ),
        },
    ]

    //Task Done modal
    const [taskDoneShow, setTaskDoneShow] = useState(false);
    const [taskDoneData, setTaskDoneData] = useState('');
    const [taskDoneValue, setTaskDoneValue] = useState({
        "id": "",
        "remark": ""
    });
    const taskDoneModalClose = () => {
        setTaskDoneShow(false);
        setTaskDoneData('')
        setTaskDoneValue({ ...taskDoneValue, id: "", remark: "" })
    }
    const taskDoneModalShow = (data) => {
        setTaskDoneShow(true);
        setTaskDoneData(data)
        setTaskDoneValue({ ...taskDoneValue, id: data.id })
    }
    const TaskDoneSubmit = (e) => {
        e.preventDefault();
        PrivateAxios.post(`checksheet/audit-done/${taskDoneValue.id}`, taskDoneValue)
            .then((res) => {
                setAssignByMeTableAllData(prevent => prevent.filter(data => data.id != taskDoneValue.id))
                setAssignByMeTableData(prevent => prevent.filter(data => data.id != taskDoneValue.id))
                taskDoneModalClose();
                SuccessMessage(res.data.msg)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    //Task Reopen modal
    const [taskReOpenShow, setTaskReOpenShow] = useState(false);
    const [taskReOpenData, setTaskReOpenData] = useState('');
    const [taskReOpenValue, setTaskReOpenValue] = useState({
        "id": "",
        "plane_date": "",
        "remark": ""
    });
    const taskReOpenModalClose = () => {
        setTaskReOpenShow(false);
        setTaskReOpenData('')
        setTaskReOpenValue({ ...taskReOpenValue, id: "", plane_date: "", remark: "" })
    }
    const taskReOpenModalShow = (data) => {
        setTaskReOpenShow(true);
        setTaskReOpenData(data);
        setTaskReOpenValue({ ...taskReOpenValue, id: data.id, plane_date: new Date(data.due_date) })
    }
    const SubmitReopen = (e) => {
        e.preventDefault()
        PrivateAxios.post(`checksheet/audit-reopen/${taskReOpenValue.id}`, taskReOpenValue)
            .then((res) => {
                GetToDOTask();
                taskReOpenModalClose();
                SuccessMessage(res.data.message)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const generatePdf = () => {
        exportPDF(assignByMeColumns, assignByMeTableData, "CheckList")
    }
    const generateExcel = () => {
        exportExcel(assignByMeColumns, assignByMeTableData, "CheckList")
    }
    const generatePrint = () => {
        printTable(assignByMeColumns, assignByMeTableData, "CheckList")
    }
    const handleKeyUpSearch = (e) => {
        const filteredItems = assignByMeTableAllData.filter((item) => {
            return item && item.checkSheetList && item.checkSheetList.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setAssignByMeTableData(filteredItems);
    }

    //====================Multi Search==========================
    //======================================================================
    const [open, setOpen] = useState(false);
    const [searchData, setSearchData] = useState({
        "name": "",
        "assigned_by": "",
        "task_priority_id": "",
        "task_mode_id": "",
        "doer_id": ""
    })
    const [task, setTask] = useState({
        startData: "",
        endDate: ""
    })
    const SearchDataButton = (e) => {
        e.preventDefault();
        const filterData = assignByMeTableAllData.filter((item) => {
            return Object.keys(searchData).every(key => {
                const searchValue = searchData[key];
                let itemValue = '';
                if (key == "doer_id") {
                    itemValue = item[key];
                } else {
                    itemValue = item.checkSheetList[key];
                }
                if (!searchValue) return true;
                if (itemValue === undefined || itemValue === null) return false;
                if (typeof itemValue === 'string') {
                    return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                }

                if (typeof itemValue === 'number') {
                    return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                }
                return false;
            }) && (
                    (!task.startData || new Date(item.due_date) >= new Date(task.startData)) &&
                    (!task.endDate || new Date(item.due_date) <= new Date(task.endDate))
                )
        })
        setAssignByMeTableData(filterData);
    }

    const ClearAll = () => {
        setSearchData({
            "name": "",
            "assigned_by": "",
            "task_priority_id": "",
            "task_mode_id": "",
            "doer_id": ""
        });
        setTask({
            startData: "",
            endDate: ""
        })
        setAssignByMeTableData(assignByMeTableAllData);
    }

    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    return (
        <React.Fragment>
            <div className="gthh-controller-bar">
                <ul className="gth-controller-view-block">
                    <li>
                        <Link to="/auditor" className="gth-controller-view-item "><i class="fi fi-rr-to-do"></i>Task</Link>
                    </li>
                    <li>
                        <Link to="/auditor-checksheet" className="gth-controller-view-item active"><i className="fi fi-rr-to-do-alt me-2"></i>Checksheet</Link>
                    </li>
                    {/* <li>
                        <Link to="/auditor-flow" className="gth-controller-view-item"><i class="fi fi-rr-workflow"></i>Flow</Link>
                    </li> */}
                </ul>
            </div>
            <div className='bg-white border-bottom mb-4'>
                <div className='d-flex flex-wrap px-4 py-2 justify-content-between align-items-center'>
                    <ul className="nav nav-tabs gth-rounded-tab-2" id="systemControllerFilterTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === 'systemTaskTodo' ? 'active' : ''}`}
                                onClick={() => handleTabClick('systemTaskTodo')}
                                type="button"
                                role="tab"
                                aria-controls="systemTaskTodo"
                                aria-selected={activeTab === 'systemTaskTodo'}
                            >
                                <span className="btn-todo"><i className="fi fi-rr-dot-circle me-1"></i>To Do</span>
                            </button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === 'systemTaskCompleted' ? 'active' : ''}`}
                                onClick={() => handleTabClick('systemTaskCompleted')}
                                type="button"
                                role="tab"
                                aria-controls="systemTaskCompleted"
                                aria-selected={activeTab === 'systemTaskCompleted'}
                            >
                                <span className="btn-completed"><i className="fi fi-rs-check-circle me-1"></i>Completed</span>
                            </button>
                        </li>
                    </ul>
                    <div className='d-flex my-1 ms-auto'>
                        <button className="btn btn-exp-info btn-sm" onClick={() => { setOpen(!open); ClearAll() }} data-bs-toggle="collapse" href="#contentIdAssignByChecksheet" aria-expanded="false" aria-controls="contentIdAssignByChecksheet" >
                            <i className="fi fi-rr-filter-list me-2"></i>Filter
                        </button>
                    </div>
                </div>
                <div className='w-100 custom-collapse'>
                    <div
                        className="collapse px-4 py-2"
                        id="contentIdAssignByChecksheet"
                    >
                        <form id='search-form'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h5 className='card-title font-weight-medium'>
                                        Search Checksheet
                                    </h5>
                                </div>
                                <div className='card-body pb-1'>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Task Name</label>
                                                <input
                                                    type='text'
                                                    value={searchData.name}
                                                    className='form-control'
                                                    placeholder='Enter Process Name'
                                                    onChange={(e) => setSearchData({ ...searchData, name: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Assign By</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectDoer'
                                                        options={User}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        value={User.find(data => data.id == searchData.assigned_by) || ""}
                                                        onChange={(e) => { setSearchData({ ...searchData, assigned_by: e.id }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                              ...base,
                                                              backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                              ...base,
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                              ...base,
                                                              backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                          }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Doer</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectDoer'
                                                        options={User}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        value={User.find(data => data.id == searchData.doer_id) || ""}
                                                        onChange={(e) => { setSearchData({ ...searchData, doer_id: e.id }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                              ...base,
                                                              backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                              ...base,
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                              ...base,
                                                              backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                          }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Mode</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectDoer'
                                                        options={TaskMode}
                                                        getOptionLabel={(option) => option.title}
                                                        getOptionValue={(option) => option.id}
                                                        value={TaskMode.find(data => data.id == searchData.task_mode_id) || ""}
                                                        onChange={(e) => { setSearchData({ ...searchData, task_mode_id: e.id }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                              ...base,
                                                              backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                              ...base,
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                              ...base,
                                                              backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                          }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Priority</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectDoer'
                                                        options={TaskPriority}
                                                        getOptionLabel={(option) => option.title}
                                                        getOptionValue={(option) => option.id}
                                                        value={TaskPriority.find(data => data.id == searchData.task_priority_id) || ''}
                                                        onChange={(e) => { setSearchData({ ...searchData, task_priority_id: e.id }); }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                              ...base,
                                                              backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                              ...base,
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                              ...base,
                                                              backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                              color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                          }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>From Date</label>
                                                <div className="exp-datepicker-cont">
                                                    <span className="cal-icon"><i className="bi bi-calendar3" /></span>
                                                    <DatePicker
                                                        selected={task.startData} onChange={(date) => setTask({ ...task, startData: date })}
                                                        dateFormat="dd/MM/YYYY"
                                                        placeholderText='Select Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>To Date</label>
                                                <div className="exp-datepicker-cont">
                                                    <span className="cal-icon"><i className="bi bi-calendar3" /></span>
                                                    <DatePicker
                                                        selected={task.endDate} onChange={(date) => setTask({ ...task, endDate: date })}
                                                        dateFormat="dd/MM/YYYY"
                                                        placeholderText='Select Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <button className="btn btn-exp-green" onClick={SearchDataButton} >Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='gth-dashboard-v2-process-wrap'>
                <div className="tab-content pt-0">
                    <div className={`tab-pane ${activeTab === 'systemTaskTodo' ? 'active' : ''}`} id="systemTaskTodo" role="tabpanel">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                    <div className='table-button-group mb-3'>
                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                             <i className="fi fi-rr-file-csv"></i>
                                        </button>
                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                            <i className="fi fi-rr-file-pdf"></i>
                                        </button>
                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                             <i className="fi fi-rr-print"></i>
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                        <label className='mr-2 mb-0'>Search: </label>
                                        <input type='text' placeholder='Type here... ' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                    </div>
                                </div>
                                <DataTable
                                    columns={assignByMeColumns}
                                    data={assignByMeTableData}
                                    pagination={[5, 10, 25, 50]}
                                    theme="solarized"
                                    striped
                                    className='custom-table-wrap workflow-table-striped'
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'systemTaskCompleted' ? 'active' : ''}`} id="systemTaskCompleted" role="tabpanel">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                    <div className='table-button-group mb-3'>
                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                             <i className="fi fi-rr-file-csv"></i>
                                        </button>
                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                            <i className="fi fi-rr-file-pdf"></i>
                                        </button>
                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                             <i className="fi fi-rr-print"></i>
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                        <label className='mr-2 mb-0'>Search: </label>
                                        <input type='text' placeholder='Type here... ' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                    </div>
                                </div>
                                <DataTable
                                    columns={assignByMeColumns}
                                    data={assignByMeTableData}
                                    pagination={[5, 10, 25, 50]}
                                    theme="solarized"
                                    striped
                                    className='custom-table-wrap workflow-table-striped'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Task Done modal start */}
            <Modal id="task-done-modal"
                show={taskDoneShow}
                onHide={taskDoneModalClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title className="gth-modal-title">Complete Task of <span>{taskDoneData && taskDoneData.checkSheetList && taskDoneData.checkSheetList.name}</span></Modal.Title>
                </Modal.Header>
                <form onSubmit={TaskDoneSubmit}>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label className="form-label">Assigned By : </label>
                                    {taskDoneData && User && User.map((item) => (
                                        item.id == taskDoneData.checkSheetList.assigned_by ?
                                            < div className="profile-wrap" key={item.id}>
                                                <div className="exp-avtar bg-exp-yellow">
                                                    {item.user_photo != null ?
                                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                    }
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                                </div>
                                            </div> : ""
                                    ))
                                    }
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Mode : </label>
                                    <p>{taskDoneData && TaskMode && TaskMode.find(data => data.id == taskDoneData.checkSheetList.task_mode_id) && TaskMode.find(data => data.id == taskDoneData.checkSheetList.task_mode_id).title}</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Planned Date : </label>
                                    <p>{taskDoneData && taskDoneData.due_date ? moment(taskDoneData.due_date).format('DD-MM-YYYY | hh:mm A') : ""}</p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label className="form-label">Remarks : </label>
                                    <textarea required className="form-control" name="remarks" rows="3" onChange={(e) => setTaskDoneValue({ ...taskDoneValue, remark: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-exp-green'>
                            Confirm
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Task Done modal end */}
            {/* Task ReOpen modal start */}
            <Modal id="task-reopen"
                show={taskReOpenShow}
                onHide={taskReOpenModalClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title className="gth-modal-title">Re-open <span>{taskReOpenData && taskReOpenData.checkSheetList && taskReOpenData.checkSheetList.name}</span></Modal.Title>
                </Modal.Header>
                <form onSubmit={SubmitReopen}>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label className="form-label">Assigned By : </label>
                                    {taskReOpenData && User && User.map((item) => (
                                        item.id == taskReOpenData.checkSheetList.assigned_by ?
                                            < div className="profile-wrap" key={item.id}>
                                                <div className="exp-avtar bg-exp-yellow">
                                                    {item.user_photo != null ?
                                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                    }
                                                </div>
                                                <div className="ps-2 profile-name-wrap text-truncate">
                                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                                </div>
                                            </div> : ""
                                    ))
                                    }
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Mode : </label>
                                    <p>{taskReOpenData && TaskMode && TaskMode.find(data => data.id == taskReOpenData.checkSheetList.task_mode_id) && TaskMode.find(data => data.id == taskReOpenData.checkSheetList.task_mode_id).title}</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Planned Date : </label>
                                    <p>{taskReOpenData && taskReOpenData.due_date ? moment(taskReOpenData.due_date).format('DD-MM-YYYY | hh:mm A') : ""}</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Complete Date : </label>
                                    <p>{taskReOpenData && taskReOpenData.completed_date ? moment(taskReOpenData.completed_date).format('DD-MM-YYYY | hh:mm A') : ""}</p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Re-Planned Date <span className='text-exp-red'>*</span></label>
                                    <div className="exp-datepicker-cont">
                                        <span className="cal-icon"><i class="fi fi-rr-calendar" /></span>
                                        <DatePicker
                                            selected={taskReOpenValue.plane_date}
                                            onChange={(date) => setTaskReOpenValue({ ...taskReOpenValue, plane_date: date })}
                                            showTimeSelect
                                            timeIntervals={15}
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            placeholderText='Select Date'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label className="form-label">Remarks : </label>
                                    <textarea required className="form-control" value={taskReOpenValue.remark} name="remarks" rows="3" onChange={(e) => setTaskReOpenValue({ ...taskReOpenValue, remark: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-exp-green'>
                            Re-Open
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Task ReOpen modal end */}
            <CheckSheetDetails showChecksheetViewDetails={taskInfoShow} handleCloseChecksheetViewDetails={TaskInfoHide} taskId={taskInfoValue.id} checkSheet={taskInfoValue && taskInfoValue.checkSheetList} assignTo={taskInfoValue.doer_id} Auditor={taskInfoValue.auditor_id} auditRemark={taskInfoValue.audit_done_remark}/>

        </React.Fragment>
    )
}

export default AuditorChecksheet
import React from 'react'
import { Placeholder } from 'react-bootstrap'
import './circleplaceholder.min.css'

function ChartPlaceholder() {
    return (
        <>
            <div className='circle_placeholder'>
                <Placeholder as="div" animation="wave">
                    <Placeholder xs={12} />
                </Placeholder>
            </div>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={4} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
            </Placeholder>
        </>
    )
}

export default ChartPlaceholder
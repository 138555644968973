import React from 'react'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { UserAuth } from '../auth/Auth';
import { url } from '../../environment/AxiosInstance';

function ViewHelpTicket({ showViewHelpDeskModal, handleCloseViewHelpDeskModal, data }) {
  const { User } = UserAuth();
  const DownloadFile = () => {
    const fileExtension = data.file.split('.').pop();
    const fileName = `ticket.${fileExtension}`;
    const link = document.createElement('a');
    link.href = url + data.file;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const DownloadFileDone = () => {
    const fileExtension = data.done_file.split('.').pop();
    const fileName = `ticket.${fileExtension}`;
    const link = document.createElement('a');
    link.href = url + data.done_file;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const attachmentListAction = (
    <Popover id="statusChange" className="action-wrap">
      <div className="action-list" onClick={DownloadFile}>
        <div className="action-list-item" >
          <i class="fi fi-sr-download me-2"></i>
          <span>Download</span>
        </div>
      </div>
    </Popover>
  );
  const attachmentListActionDone = (
    <Popover id="statusChange" className="action-wrap">
      <div className="action-list" onClick={DownloadFileDone}>
        <div className="action-list-item" >
          <i class="fi fi-sr-download me-2"></i>
          <span>Download</span>
        </div>
      </div>
    </Popover>
  )

  return (
    <Modal id="view-HelpDesk-modal" show={showViewHelpDeskModal} onHide={handleCloseViewHelpDeskModal} backdrop="static" centered size="xl">
      <Modal.Header closeButton className="gth-blue-light-bg">
        <Modal.Title className="gth-modal-title">Ticket No: <span>{data && data.id}</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="form-label">Doer</label>
              <div>
                {data && User && User.map((item) => (
                  item.id == data.assign_to ?
                    < div className="profile-wrap" key={item.id}>
                      <div className="exp-avtar bg-exp-yellow">
                        {item.user_photo != null ?
                          < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                          <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                        }
                      </div>
                      <div className="ps-2 profile-name-wrap text-truncate">
                        <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                      </div>
                    </div> : ""
                ))
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mb-1">
              <label className="form-label">CC</label>
              <div className='d-flex flex-wrap cc-wrap-raise-tome'>
                {data && data.users && data.users.length > 0 ?
                  data.users.map((itemData => (
                    <div className="profile-wrap me-3 mb-2">
                      {data && User && User.map((item) => (
                        itemData.user_id != data.assign_to ?
                          item.id == itemData.user_id ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-yellow">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : "" : ""
                      ))
                      }
                    </div>
                  ))) : ""
                }

              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label">Title</label>
              <p className="mb-0">{data && data.subject}</p>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label">Message</label>
              <p className="mb-0">{data && data.description}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="card shadow-none border grid-files mt-2 mb-0">
                <div className="card-header bg-exp-primary-grey-light-1">
                  <h6>Files</h6>
                </div>
                <div className="card-body">
                  <div className="gth-attachment-body">
                    {data && data.file && data.file != '' ? data.file.split('.').pop() == "xls" || data.file.split('.').pop() == "xlsx" ?
                      <figure className="gth-attachment-tile-item">
                        <div className="grid-action-btn">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="bottom"
                            overlay={attachmentListAction}
                          >
                            <button className="action-btn">
                              <i class="fi fi-br-menu-dots-vertical"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                        <div className="attachment-image">
                          <span className="related-icon">
                            <i className="fi fi-rr-file-excel" />
                          </span>
                        </div> </figure> : data.file.split('.').pop() == "pdf" ?
                        <figure className="gth-attachment-tile-item">
                          <div className="grid-action-btn">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="bottom"
                              overlay={attachmentListAction}
                            >
                              <button className="action-btn">
                                <i class="fi fi-br-menu-dots-vertical"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                          <div className="attachment-image">
                            <span className="related-icon">
                              <i className="fi fi-rr-file-pdf" />
                            </span>
                          </div>
                        </figure> : data.file.split('.').pop() == "png" || data.file.split('.').pop() == "jpg" || data.file.split('.').pop() == "jpeg" ?
                          <figure className="gth-attachment-tile-item">
                            <div className="grid-action-btn">
                              <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="bottom"
                                overlay={attachmentListAction}
                              >
                                <button className="action-btn">
                                  <i class="fi fi-br-menu-dots-vertical"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                            <div className="attachment-image">
                              <span className="related-icon">
                                <i className="fi fi-rr-picture" />
                              </span>
                            </div>
                          </figure> : data.file.split('.').pop() == "doc" ?
                            <figure className="gth-attachment-tile-item">
                              <div className="grid-action-btn">
                                <OverlayTrigger
                                  trigger="click"
                                  rootClose
                                  placement="bottom"
                                  overlay={attachmentListAction}
                                >
                                  <button className="action-btn">
                                    <i class="fi fi-br-menu-dots-vertical"></i>
                                  </button>
                                </OverlayTrigger>
                              </div>
                              <div className="attachment-image">
                                <span className="related-icon">
                                  <i className="fi fi-rr-document" />
                                </span>
                              </div>
                            </figure> : "" : ""
                    }
                  </div>
                </div>
              </div >
            </div >
          </div >
          {data && data.remark ?
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">Close Remark</label>
                <p className="mb-0">{data && data.remark}</p>
              </div>
            </div> : ""
          }
          {data && data.done_remark && data.done_remark != '' ?
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">Doer Remarks After Completion</label>
                <p className="mb-0">{data && data.done_remark}</p>
              </div>
            </div> : ""
          }{data && data.done_file && data.done_file != '' ?
            <div className="col-lg-12">
              <div className="form-group">
                <div className="card shadow-none border grid-files mt-2 mb-0">
                  <div className="card-header bg-exp-primary-grey-light-1">
                    <h6>Files</h6>
                  </div>
                  <div className="card-body">
                    <div className="gth-attachment-body">
                      {data && data.done_file && data.done_file != '' ? data.done_file.split('.').pop() == "xls" || data.done_file.split('.').pop() == "xlsx" ?
                        <figure className="gth-attachment-tile-item">
                          <div className="grid-action-btn">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="bottom"
                              overlay={attachmentListActionDone}
                            >
                              <button className="action-btn">
                                <i class="fi fi-br-menu-dots-vertical"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                          <div className="attachment-image">
                            <span className="related-icon">
                              <i className="fi fi-rr-file-excel" />
                            </span>
                          </div> </figure> : data.done_file.split('.').pop() == "pdf" ?
                          <figure className="gth-attachment-tile-item">
                            <div className="grid-action-btn">
                              <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="bottom"
                                overlay={attachmentListActionDone}
                              >
                                <button className="action-btn">
                                  <i class="fi fi-br-menu-dots-vertical"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                            <div className="attachment-image">
                              <span className="related-icon">
                                <i className="fi fi-rr-file-pdf" />
                              </span>
                            </div>
                          </figure> : data.done_file.split('.').pop() == "png" || data.done_file.split('.').pop() == "jpg" || data.done_file.split('.').pop() == "jpeg" ?
                            <figure className="gth-attachment-tile-item">
                              <div className="grid-action-btn">
                                <OverlayTrigger
                                  trigger="click"
                                  rootClose
                                  placement="bottom"
                                  overlay={attachmentListActionDone}
                                >
                                  <button className="action-btn">
                                    <i class="fi fi-br-menu-dots-vertical"></i>
                                  </button>
                                </OverlayTrigger>
                              </div>
                              <div className="attachment-image">
                                <span className="related-icon">
                                  <i className="fi fi-rr-picture" />
                                </span>
                              </div>
                            </figure> : data.done_file.split('.').pop() == "doc" ?
                              <figure className="gth-attachment-tile-item">
                                <div className="grid-action-btn">
                                  <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="bottom"
                                    overlay={attachmentListActionDone}
                                  >
                                    <button className="action-btn">
                                      <i class="fi fi-br-menu-dots-vertical"></i>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                <div className="attachment-image">
                                  <span className="related-icon">
                                    <i className="fi fi-rr-document" />
                                  </span>
                                </div>
                              </figure> : "" : ""
                      }
                    </div>
                  </div>
                </div >
              </div >
            </div >
            : ""
          }
        </div >
      </Modal.Body >
    </Modal >
  )
}

export default ViewHelpTicket
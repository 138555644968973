import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Legend, Colors } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);

const optionsWorkflow = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
    },
    cutout: '60%',
}

function WorkflowChartWidget({ workFlowChart }) {

    const totalTasks = (workFlowChart?.todo || 0) +
        (workFlowChart?.inprogress || 0) +
        (workFlowChart?.complete || 0) +
        (workFlowChart?.delay || 0) +
        (workFlowChart?.completeDelay || 0);

    const hasData = totalTasks > 0;

    const data = {
        labels: ['Todo', 'In Progress', 'Completed', 'Delay', 'Delay Completed'],
        datasets: [
            {
                data: [workFlowChart && workFlowChart.todo, workFlowChart && workFlowChart.inprogress, workFlowChart && workFlowChart.complete, workFlowChart && workFlowChart.delay, workFlowChart && workFlowChart.completeDelay],
                backgroundColor: [
                    // 'rgb(233, 235, 238)',
                    'rgb(127, 127, 127)',
                    'rgb(89, 71, 208)',
                    'rgb(63, 213, 52)',
                    'rgb(255, 230, 43)',
                    'rgb(227, 36, 36)',
                ],
                borderWidth: 0,
            },
        ],
    };
    //dark theme image change
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    useEffect(() => {
        // Check for the theme on initial load
        const currentTheme = document.body.classList.contains("dark-theme");
        setIsDarkTheme(currentTheme);

        // Listen for changes to the body's class (if toggling happens outside this component)
        const observer = new MutationObserver(() => {
            const updatedTheme = document.body.classList.contains("dark-theme");
            setIsDarkTheme(updatedTheme);
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme image change end
    return (
        <>
            <div className='card text-center graph-card h-100 mb-0'>
                <div className='card-body'>
                    <h5 className='card-title mb-2'>Work Flow</h5>
                    {hasData ?
                        <div className='chart-wrap'>
                            <Doughnut options={optionsWorkflow} data={data} />
                        </div> :
                        <div className='chart-wrap-nodata'>                            
                            <img
                                src={
                                    isDarkTheme
                                        ? `${process.env.PUBLIC_URL}/assets/images/graph-nodata-dark.png`
                                        : `${process.env.PUBLIC_URL}/assets/images/graph-nodata.png`
                                }
                                className="img-fluid"
                                alt="no-data"
                            />
                        </div>

                        // <div style={{ height: "100%", display: 'flex', justifyContent: "center", alignItems: "center", fontSize: "20px" }}> <p>No Data Found</p></div>

                    }
                </div>
            </div>
        </>
    )
}

export default WorkflowChartWidget
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PrivateAxios } from '../../../environment/AxiosInstance'
import Loader from '../../landing/loder/Loader';
import { SuccessMessage } from '../../../environment/ToastMessage';
import moment from 'moment';
import { UserAuth } from '../../auth/Auth';
import RoleCreate from './RoleCreate';
import UpdateRole from './UpdateRole';
import DeleteModel from '../../../component/DeleteModel';

function Role() {
    const [loading, setLoading] = useState(false);
    const [holiday, setHoliday] = useState([]);
    const [update, setUpdate] = useState(false)
    const [create, setCreate] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [holidayValue, setHolidayValue] = useState('')
    const [holidayInputValue, setHolidayInputValue] = useState({
        "name": "",
        "date": "",
        "status": ""
    })
    const { Logout, userDetails, MatchPermission } = UserAuth();
    const [role, setRole] = useState([])
    const [roleAll, setRoleAll] = useState([])

    // 
    const GetRole = () => {
        PrivateAxios.get('get-role')
            .then((res) => {
                setRoleAll(res.data.data);
                setRole(res.data.data);
                // console.log(res.data.data);

            }).catch((err) => {


            })
    }
    useEffect(() => {
        GetRole();
    }, [])


    // for Role modal start
    const [showRoleModal, setShowRoleModal] = useState(false);
    const handleCloseRoleModal = () => setShowRoleModal(false);
    const handleShowRoleModal = () => setShowRoleModal(true);
    //======================Edit Role modal start=========================
    const [showEditRoleModal, setShowEditRoleModal] = useState(false);
    const [showEditRoleData, setShowEditRoleData] = useState('');
    const handleCloseEditRoleModal = () => {
        setShowEditRoleModal(false);
        setShowEditRoleData('')
    }
    const handleShowEditRoleModal = (data) => {
        setShowEditRoleModal(true);
        setShowEditRoleData(data)
    }

    //===================delete modal=======================
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteModalId, setDeletModaleId] = useState('');

    const deleteModalClose = () => {
        setDeleteShow(false);
        setDeletModaleId('')
    }
    const deleteModalShow = (id) => {
        setDeleteShow(true);
        setDeletModaleId(id)
    }

    const DeleteRole = () => {
        PrivateAxios.delete(`delete-role/${deleteModalId}`)
            .then((res) => {
                SuccessMessage(res.data.msg)
                deleteModalClose();
                GetRole();
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    return (
        <>
            {loading ? <Loader /> :
                < div className='p-4'>
                    <div className='w-100 d-flex justify-content-end mb-3'>
                        {MatchPermission(["Add Role"]) ?
                            <button onClick={handleShowRoleModal} className='btn btn-exp-green'>
                                <i className='bi bi-plus-circle me-2'></i>
                                Create New
                            </button> : ""
                        }
                    </div>
                    <div className='card overflow-hidden'>
                        {/* <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                            <div className='table-button-group mb-3'>
                                <button className='btn table-export-btn'  >
                                    <img src={process.env.PUBLIC_URL + 'assets/images/file-csv.svg'} alt="icon" />
                                </button>
                                <button className='btn table-export-btn'>
                                    <img src={process.env.PUBLIC_URL + 'assets/images/file-pdf.svg'} alt="icon" />
                                </button>
                                <button className='btn table-export-btn' >
                                    <img src={process.env.PUBLIC_URL + 'assets/images/print.svg'} alt="icon" />
                                </button>
                            </div>
                            <div className='d-flex align-items-center mb-3 ms-auto'>
                                <label className='mr-2 mb-0'>Search: </label>
                                <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                            </div>
                        </div> */}
                        <div className='card-body p-0'>
                            <table class="table table-striped border-top">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: 100 }}>SL.No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col" style={{ width: 150 }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {role.length > 0 ? role.map((item, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td style={{ width: 150 }}>
                                                {
                                                    item.company_id && item.company_id != 0 || userDetails.position == "Owner" ?
                                                        <div className="d-flex">
                                                            {MatchPermission(["Edit Role"]) ?
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Edit
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button className="me-1 table-action-btn" onClick={() => handleShowEditRoleModal(item)}>
                                                                        <i className='fi fi-rr-pencil'></i>
                                                                    </button>
                                                                </OverlayTrigger> : ""
                                                            }
                                                            {MatchPermission(["Delete Role"]) ?
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Delete
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button className="me-1 table-action-btn" onClick={() => deleteModalShow(item.id)}>
                                                                        <i className='fi fi-rr-trash text-exp-red'></i>
                                                                    </button>
                                                                </OverlayTrigger> : ""
                                                            }
                                                        </div>
                                                        : ""
                                                }
                                            </td>
                                        </tr>)) : ""

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            }

            {/* Create Role start */}
            <RoleCreate showRoleModal={showRoleModal} GetRole={GetRole} handleCloseRoleModal={handleCloseRoleModal} setLoading={setLoading} />
            {/* Create Role end */}
            <UpdateRole showEditRoleModal={showEditRoleModal} GetRole={GetRole} handleCloseEditRoleModal={handleCloseEditRoleModal} data={showEditRoleData} setLoading={setLoading} />
            {/* Edit Role start */}

            {/* Edit Role end */}



            {/* Delete modal start */}
            <DeleteModel deleteShow={deleteShow} deleteModalClose={deleteModalClose} deleteAction={DeleteRole} />
            {/* <form>
                <Modal id="delete-modal"
                    show={deleteShow}
                    onHide={deleteModalClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className="gth-light-red-bg">
                        <Modal.Title className="gth-text-danger">Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="delete-confirm-wrap text-center">
                            <div className="delete-confirm-icon mb-3 mt-2">
                                <img src={process.env.PUBLIC_URL + 'assets/images/delete-warning.svg'} alt="Warning" className="img-fluid" />
                            </div>
                            <h4 className="text-muted">Are you sure?</h4>
                            <p className="text-muted">
                                Do you really want to delete these record? This process cannot be undone.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <button className='btn btn-secondary' onClick={deleteModalClose}>
                            Cancel
                        </button>
                        <button className='btn btn-exp-red'>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>
            </form> */}
            {/* Delete modal end */}
        </>
    )
}

export default Role
import React, { useEffect, useState } from 'react'
import { Collapse, Modal } from 'react-bootstrap'
import { UserAuth } from '../auth/Auth'
import { PrivateAxios, url } from '../../environment/AxiosInstance';

function UserDetails({ viewUserShow, viewUserModalClose, data, isDelete, role, permissionInitial }) {
    const [selectRole, setSelectRole] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [allPermission, setAllPermission] = useState([]);
    const [ExtraPermission, setExtraPermission] = useState([]);
    const { GetUserPermission, Logout, User } = UserAuth();
    const [userData, setUserData] = useState({
        "name": "",
        "email": "",
        "p_isd": "",
        "phone": "",
        "w_isd": "",
        "wsnumber": "",
        "depertment_id": "",
    });



    const handleSelectAllChange = (e) => {
        const newValue = e.target.checked;
        setSelectAll(newValue);
        setGroups(prevent => prevent.map(group => ({ ...group, selected: newValue, allmodule: group.allmodule.map(item => ({ ...item, selected: newValue })) })))
        if (newValue) {
            const permissionsToAdd = [];
            groups.forEach(group => {
                group.allmodule.forEach(item => {
                    permissionsToAdd.push({ module_id: group.id, permission_id: item.id, name: item.name });
                });
            });
            setAllPermission([...allPermission, ...permissionsToAdd]);
            setExtraPermission([...ExtraPermission, ...permissionsToAdd])
        } else {
            setAllPermission([]);
            setExtraPermission([])
        }
    };
    const handleGroupChange = (groupId, e) => {
        const newValue = e.target.checked;
        setGroups(groups.map(group => group.id === groupId ? { ...group, selected: newValue, allmodule: group.allmodule.map(item => ({ ...item, selected: newValue })) } : group));
        if (newValue) {
            const permissionsToAdd = groups
                .find(group => group.id === groupId)
                .allmodule.map(item => ({ module_id: groupId, permission_id: item.id, name: item.name }));
            setAllPermission([...allPermission, ...permissionsToAdd]);
            setExtraPermission([...ExtraPermission, ...permissionsToAdd])
        } else {
            setAllPermission(allPermission.filter(
                permission => permission.module_id !== groupId
            ));
            setExtraPermission(ExtraPermission.filter(
                permission => permission.module_id !== groupId
            ))
        }
    };

    const handleItemChange = (groupId, itemId, name, e) => {
        if (e.target.checked) {
            setAllPermission([...allPermission, { module_id: groupId, permission_id: itemId, name: name }])
            setExtraPermission([...ExtraPermission, { module_id: groupId, permission_id: itemId, name: name }])
        } else {
            setAllPermission(allPermission.filter(item =>
                !(item.module_id === groupId && item.permission_id === itemId)
            ));
            setExtraPermission(ExtraPermission.filter(item =>
                !(item.module_id === groupId && item.permission_id === itemId)
            ));
        }
        setGroups(groups.map(group =>
            group.id === groupId ? {
                ...group,
                allmodule: group.allmodule.map(item =>
                    item.id === itemId ? { ...item, selected: !item.selected } : item
                ),
                selected: group.allmodule.every(item => item.id === itemId ? !item.selected : item.selected)
            } : group
        ));

        const allGroupsSelected = groups.every(group =>
            group.allmodule.every(item => item.selected)
        );
        setSelectAll(allGroupsSelected);
    };

    // Clear all state when modal is closed
    const clearALL = () => {
        setAllPermission([]);
        setSelectRole([]);
        setGroups([]);
        setUserData({
            "name": "",
            "email": "",
            "p_isd": "",
            "phone": "",
            "w_isd": "",
            "wsnumber": "",
            "depertment_id": "",
        });
        setSelectAll(false);
    }
    useEffect(() => {
        // if (editUserShow) {
        if (permissionInitial?.length > 0) {
            // Deep copy to prevent mutation
            const initialGroups = JSON.parse(JSON.stringify(permissionInitial));
            setGroups(initialGroups);
        }
        // }
    }, [permissionInitial]);
    // Initialize groups when modal opens


    // Initialize user data and permissions when modal opens
    useEffect(() => {
        if (data) {
            if (data) {
                setUserData({
                    name: data.name,
                    email: data.email,
                    p_isd: data.p_isd,
                    phone: data.phone_number,
                    w_isd: data.w_isd,
                    wsnumber: data.whatsapp_no,
                    depertment_id: data.departments_id,
                });

                // Set selected roles
                // const existRole = data.role[0];
                console.log(data.role);

                if (data.role?.length > 0) {
                    let roleIds = []
                    data.role.map(roleId =>
                        roleIds.push({ role_id: roleId.role_id })
                    );
                    setSelectRole(roleIds)
                    let permissionData = [];
                    let extraPermissions = [];
                    data.role.map(roleId => {
                        if (roleId.permission_id) {
                            const permissionID = JSON.parse(roleId.permission_id)
                            permissionID.map((item) => {
                                if (!permissionData.includes(item)) {
                                    permissionData.push(item)
                                }
                            })
                        }
                        if (roleId.role_id == 0) {
                            if (roleId.permission_id) {
                                const permissionIDS = JSON.parse(roleId.permission_id)
                                permissionIDS.map((item) => {
                                    extraPermissions.push(item)
                                })
                            }
                        }
                    }
                    );
                    if (permissionData.length > 0) {
                        setGroups(prevGroups => prevGroups.map(group => {
                            const updatedModules = group.allmodule.map(module => {
                                const selected = permissionData.includes(module.id);
                                return { ...module, selected };
                            });
                            const selectedGroup = updatedModules.every(module => module.selected);
                            return { ...group, allmodule: updatedModules, selected: selectedGroup };
                        }));
                        const allPermissions = [];
                        const allExtraPermissions = [];
                        permissionInitial.forEach(group => {
                            group.allmodule.forEach(module => {
                                if (permissionData.includes(module.id)) {
                                    allPermissions.push({ module_id: group.id, permission_id: module.id, name: module.name });
                                }
                                if (extraPermissions.includes(module.id)) {
                                    allExtraPermissions.push({ module_id: group.id, permission_id: module.id, name: module.name });
                                }
                            });
                        });
                        setAllPermission(allPermissions);
                        setExtraPermission(allExtraPermissions)
                    }
                }
            } else {
                clearALL();
            }
        }
    }, [data, data, permissionInitial]);
    useEffect(() => {
        const allSelected = groups.every(group => group.selected);
        setSelectAll(allSelected);
    }, [groups]);



    //role permission collapse
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);


    return (
        <Modal id="viewUserModal" show={viewUserShow} onHide={viewUserModalClose} backdrop="static" keyboard={false} centered size="lg">
            <Modal.Header closeButton className="gth-blue-light-bg">
                <Modal.Title className="gth-modal-title">
                    {isDelete == 0 ?
                        <div className="profile-wrap">
                            <div className="exp-avtar bg-exp-yellow">
                                {data && <span>{data.name != '' ? data.name.split(' ').length > 1 ? data.name.split(' ')[0].split('')[0] + data.name.split(' ')[1].split('')[0] : data.name.split('')[0] + data.name.split('')[1] : ""}</span>}
                            </div>
                            <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap">{data && data.name}</h5>
                            </div>
                        </div> :
                        <>
                            {User && User.map((item) => (
                                item.id == data.id ?
                                    < div className="profile-wrap" key={item.id}>
                                        <div className="exp-avtar bg-exp-yellow">
                                            {item.user_photo != null ?
                                                < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                            }
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                        </div>
                                    </div> : ""))
                            }
                        </>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='pb-1'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label className="form-label">User Name</label>
                            <p className="mb-0">{data && data.name}</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label className="form-label">Email</label>
                            <p className="mb-0">{data && data.email}</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label className="form-label">Phone</label>
                            <p className="mb-0">+{data && data.p_isd} {data && data.phone_number}</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label className="form-label">WhatsApp</label>
                            <p className="mb-0">+{data && data.w_isd} {data && data.whatsapp_no}</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <label className="form-label">Department</label>
                            <p className="mb-0">{data && data.depart.title}</p>
                        </div>
                    </div>
                </div>
                {isDelete == 1 ?
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="card shadow-none border">
                                    <div className="card-header bg-primary-grey-light-2 d-flex align-items-center justify-content-between">
                                        <h6 className="mb-0"><i className="fi fi-rr-admin-alt me-2 gth-text-primary"></i>Role Management</h6>
                                        <label
                                            onClick={() => setOpen(!open)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                            className='icon-btn mb-0 ms-auto cursor-pointer'
                                        >
                                            <i className="fi fi-br-angles-up-down"></i>
                                        </label>
                                    </div>
                                    <Collapse in={open}>
                                        <div className="card-body pb-3">
                                            <div className="d-flex flex-wrap">
                                                {role.length > 0 ? role.map((item) => (
                                                    <label className="custom-checkbox me-3 mb-2">
                                                        <input type="checkbox" checked={selectRole.find((data) => data.role_id === item.id) ? true : false}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    return;
                                                                } else {
                                                                    return;
                                                                }
                                                            }} />
                                                        <span className="checkmark" />
                                                        <span className="text-">{item.name}</span>
                                                    </label>)) : ""
                                                }
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <div className='card shadow-none border'>
                                    <div className="card-header bg-primary-grey-light-2 d-flex align-items-center justify-content-between">
                                        <h6 className="mb-0"><i className="fi fi-rr-plan me-2 gth-text-primary"></i>Additional Permissions</h6>
                                        <label
                                            onClick={() => setOpen2(!open2)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={open}
                                            className='icon-btn mb-0 ms-auto cursor-pointer'
                                        >
                                            <i className="fi fi-br-angles-up-down"></i>
                                        </label>
                                    </div>
                                    <Collapse in={open2}>
                                        <div className='card-body role-permission-card'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label className="custom-checkbox mb-0">
                                                            <input type="checkbox" checked={selectAll} />
                                                            <span className="checkmark" />
                                                            <span className="text- text-dark">All</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    groups && groups.map((item, i) => (
                                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                                            <div className='form-group'>
                                                                <label className="custom-checkbox mb-2">
                                                                    <input type="checkbox" checked={item.selected} />
                                                                    <span className="checkmark" />
                                                                    <span className="text- text-dark">{item.name}</span>
                                                                </label>
                                                                <div className='ps-3'>
                                                                    {
                                                                        item.allmodule && item.allmodule.map((data) => (
                                                                            <label className="custom-checkbox mb-2">
                                                                                <input type="checkbox" checked={data.selected} />
                                                                                <span className="checkmark" />
                                                                                <span className="text-">{data.name}</span>
                                                                            </label>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                }
            </Modal.Body>
        </Modal>
    )
}

export default UserDetails

import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Legend, Colors } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);

const optionsChecksheet = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
        // title: {
        //     display: true,
        //     fontSize: 20,
        //     text: 'Checksheet'
        // }
    },
    cutout: '60%',
}

function ChecksheetChartWidget({ ChecksheetCharts }) {

    const totalTasks = (ChecksheetCharts?.todo || 0) +
        (ChecksheetCharts?.inprogress || 0) +
        (ChecksheetCharts?.complete || 0) +
        (ChecksheetCharts?.delay || 0) +
        (ChecksheetCharts?.completeDelay || 0);

    const hasData = totalTasks > 0;


    const data = {
        labels: ['Todo', 'In Progress', 'Completed', 'Delay', 'Delay Completed'],
        datasets: [
            {
                data: [ChecksheetCharts && ChecksheetCharts.todo, ChecksheetCharts && ChecksheetCharts.inprogress, ChecksheetCharts && ChecksheetCharts.complete, ChecksheetCharts && ChecksheetCharts.delay, ChecksheetCharts && ChecksheetCharts.completeDelay],
                backgroundColor: [
                    'rgb(127, 127, 127)',
                    'rgb(89, 71, 208)',
                    'rgb(63, 213, 52)',
                    'rgb(255, 230, 43)',
                    'rgb(227, 36, 36)',
                ],
                borderWidth: 0,
            },
        ],
    };
    //dark theme image change
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    useEffect(() => {
        // Check for the theme on initial load
        const currentTheme = document.body.classList.contains("dark-theme");
        setIsDarkTheme(currentTheme);

        // Listen for changes to the body's class (if toggling happens outside this component)
        const observer = new MutationObserver(() => {
            const updatedTheme = document.body.classList.contains("dark-theme");
            setIsDarkTheme(updatedTheme);
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme image change end
    return (
        <>
            <div className='card text-center graph-card h-100 mb-0'>
                <div className='card-body'>
                    <h5 className='card-title mb-2'>Checksheet</h5>
                    {hasData ?
                        <div className='chart-wrap'>
                            <Doughnut options={optionsChecksheet} data={data} />
                        </div> :
                        <div className='chart-wrap-nodata'>
                            <img
                                src={
                                    isDarkTheme
                                        ? `${process.env.PUBLIC_URL}/assets/images/graph-nodata-dark.png`
                                        : `${process.env.PUBLIC_URL}/assets/images/graph-nodata.png`
                                }
                                className="img-fluid"
                                alt="no-data"
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ChecksheetChartWidget
export const splitText = (str, number) => {
    if (str.length > number) {
        return str.substring(0, number) + '...';
    }
    return str;
};

export function MultipleSpacesWithUnderscores(str) {
    const multipleSpacesRegex = / {2,}/g;
    return str.replace(multipleSpacesRegex, '_');
}

// export function calculateDelayInHoursEndTime(startDate, endDate) {
//     if (startDate != null && endDate != null) {
//         if (new Date(startDate) != "Invalid Date" && new Date(endDate) != "Invalid Date") {
//             let start = new Date(startDate);
//             let end = new Date(endDate);
//             let differenceInMilliseconds = end.getTime() - start.getTime();
//             let differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
//             if (differenceInHours < 0) {
//                 return 0;
//             } else
//                 return Math.abs(differenceInHours).toFixed(2);

//         } else {
//             return 0;
//         }
//     } else {
//         return 0;
//     }
// }

export function calculateDelayInHoursEndTime(startDate, endDate) {
    if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (!isNaN(start) && !isNaN(end)) {
            const differenceInMilliseconds = end - start;
            const differenceInHours = Math.abs(differenceInMilliseconds) / (1000 * 60 * 60);

            const totalHours = Math.floor(differenceInHours);
            const remainingMinutes = Math.floor((differenceInHours % 1) * 60);

            // Determine if the result should be negative
            const isNegative = differenceInMilliseconds < 0;
            const time = `${isNegative ? '-' : ''}${totalHours}:${remainingMinutes.toString().padStart(2, '0')}`;
            if (!isNegative) {
                return time;
            } else {
                return `0:00`;
            }


        }
    }

    return `0:00`;
}

import React from 'react'
import { Link } from 'react-router-dom'

function UnauthorizedPage() {
    return (
        <>
            <div className='p-4'>
                <div className='row justify-content-center access-denied-wrap'>
                    <div className='col-lg-8'>
                        <div className='row align-items-center g-4 flex-row-reverse'>
                            <div className='col-md-7'>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/access-denied.png`} alt="access-denied" className="img-fluid" />
                            </div>
                            <div className='col-md-5'>
                                <h1 className='fw-bold '>Access to this page is Restricted</h1>
                                <p className='f-s-16 text-muted mb-2'>You are not authorized to access this feature.</p>
                                <p className='f-s-16 text-muted mb-4'>Kindly contact the administrator.</p>
                                <Link to="/dashboard" className="btn btn-primary">Go to Dashboard</Link>
                            </div>
                            
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default UnauthorizedPage



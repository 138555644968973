
import React, { useEffect, useRef, useState, useCallback } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { PrivateAxios, PrivateAxiosFile, url } from '../../environment/AxiosInstance';
import { UserAuth } from '../auth/Auth';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import { customStyles } from '../../environment/Datatable';
import Loader from '../landing/loder/Loader';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import UserDetails from './UserDetails';





function DeletedUsers() {


    const { isLoading, setIsLoading, Logout } = UserAuth()
    const [loading, setLoading] = useState(false)


    const [selectedColumns, setSelectedColumns] = useState([
        {
            name: 'Sl No.',
            selector: (row, index) => index + 1,
            minWidth: "80px",
            maxWidth: "80px"
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            width: "220px",
            cell: (row) => (
                <div className="profile-wrap">
                    <div className="exp-avtar bg-exp-green">
                        {
                            row && row.user_photo != null ?
                                < img className="prof-img" src={url + row.user_photo} alt="user" /> :
                                <span>{row && row.name != '' ? row.name.split(' ').length > 1 ? row.name.split(' ')[0].split('')[0] + row.name.split(' ')[1].split('')[0] : row.name.split('')[0] + row.name.split('')[1] : ""}</span>
                        }

                    </div>
                    <div className="ps-2 profile-name-wrap text-truncate">
                        <h5 className="profile-name text-nowrap text-truncate">{row && row.name}</h5>
                    </div>
                </div>

            ),
        },

        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
            width: "260px"
        },
        // {
        //     name: "Role",
        //     selector: (row) => "admin",
        //     sortable: true,
        //     reorder: true,
        //     maxWidth: "120px"
        // },
        {
            name: "Department",
            selector: (row) => row.depart && row.depart.title,
            sortable: true,
            reorder: true,
            maxWidth: "150px"
        },
        {
            name: "Phone Number",
            selector: (row) => row.phone_number,
            sortable: true,
            reorder: true,
            maxWidth: "180px"
        },
        {
            name: "Action",
            width: "200px",
            selector: (row) => '',
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => viewUserModalShow(row)}>
                            <i className="fi fi-rr-eye"></i>
                        </button>
                    </OverlayTrigger>


                </div>
            ),
        },
    ]);



    //==========================View User modal==========================
    const [viewUserShow, setViewUserModalShow] = useState(false);
    const [viewUserValue, setViewUserModalValue] = useState('');
    const viewUserModalClose = () => {
        setViewUserModalShow(false);
        setViewUserModalValue('')
    }
    const viewUserModalShow = (data) => {
        setViewUserModalShow(true);
        setViewUserModalValue(data)
    }




    // exportExcel
    const generatePdf = () => {
        exportPDF(selectedColumns, allUsers, "User")
    }
    const generateExcel = () => {
        exportExcel(selectedColumns, allUsers, "User")
    }
    const generatePrint = () => {
        printTable(selectedColumns, allUsers, "User")
    }


    const [allUsers, setAllUsers] = useState([])
    const [allSearchUser, setAllSearchUsers] = useState([])

    const handleKeyUpSearch = (e) => {
        const filteredItems = allUsers.filter((item) => {
            return item && item.name && item.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setAllSearchUsers(filteredItems);

    }

    const GetUser = () => {
        setLoading(true)
        PrivateAxios.get("user/delete-user")
            .then((res) => {
                setLoading(false)
                setAllUsers(res.data.user)
                setAllSearchUsers(res.data.user)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    useEffect(() => {
        GetUser();
    }, [])



    return (

        <React.Fragment>
            {loading ? <Loader /> :
                <>
                    <div className="gthh-controller-bar">
                        <ul className="gth-controller-view-block">
                            <li>
                                <Link className="gth-controller-view-item " to="/users">
                                    <i className="fi fi-rr-user-check me-2" />
                                    Active Users
                                </Link>
                            </li>
                            <li>
                                <Link className="gth-controller-view-item active" to="/deleted-users">
                                    <i className="fi fi-rr-user-minus me-2" />
                                    Deleted Users
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="p-4">
                        <div className='card'>
                            <div className='card-body p-0'>
                                {!allUsers.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        <div className="table-view" >
                                            <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                <div className='table-button-group mb-3'>
                                                    <button className='btn table-export-btn' onClick={generateExcel} >
                                                        <i className="fi fi-rr-file-csv"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePdf}>
                                                        <i className="fi fi-rr-file-pdf"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePrint}>
                                                        <i className="fi fi-rr-print"></i>
                                                    </button>
                                                </div>
                                                <div className='d-flex align-items-center mb-3 ms-auto'>
                                                    <label className='mr-2 mb-0'>Search: </label>
                                                    <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={selectedColumns}
                                                data={allSearchUser}
                                                pagination={[5, 10, 25, 50]}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap checklist-table-striped'
                                            //customStyles={customStyles}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
            <>

                {/* View User Modal Start */}
                <UserDetails viewUserShow={viewUserShow} viewUserModalClose={viewUserModalClose} data={viewUserValue} isDelete={0} />
                {/* View User Modal End */}

            </>

        </React.Fragment >

    )
}

export default DeletedUsers;
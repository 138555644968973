
import React, { useEffect, useRef, useState, useCallback } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { PrivateAxios, PrivateAxiosFile, url } from '../../environment/AxiosInstance';
import { UserAuth } from '../auth/Auth';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import { customStyles } from '../../environment/Datatable';
import Loader from '../landing/loder/Loader';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import AddUser from './AddUser';
import EditUser from './EditUser';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../environment/ToastMessage';
import moment from 'moment';
import DeleteModel from '../../component/DeleteModel';
import UserDetails from './UserDetails';





function Users() {
    const { isLoading, setIsLoading, Logout, User, MatchPermission } = UserAuth()
    const [grid, setGrid] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [error, setError] = useState({});
    const [inputSearch, setInputSearch] = useState([]);
    const [loading, setLoading] = useState(false)
    const [department, setDepartment] = useState([])
    const [role, setRole] = useState([])
    const [permissionInitial, setPermissionInitial] = useState([])

    //==========================change password modal============================
    const [changePasswordShow, setChangePasswordModalShow] = useState(false);
    const [changePasswordId, setChangePasswordId] = useState('');
    const [passwordData, setPasswordData] = useState({
        "oldPassword": "",
        "newPassword": "",
        "confirmPassword": "",
    });
    const handleChangePasswordModalClose = () => {
        setChangePasswordModalShow(false);
        setPasswordData({
            ...passwordData,
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""

        })
    }
    const handleChangePasswordModalShow = (id) => {
        setChangePasswordModalShow(true);
        setChangePasswordId(id)
    }
    const UpdatePassword = (e) => {
        e.preventDefault();
        if (passwordData.newPassword == passwordData.confirmPassword) {
            PrivateAxios.post(`user/update-password/${changePasswordId}`, passwordData)
                .then((res) => {
                    SuccessMessage(res.data.msg);
                    handleChangePasswordModalClose();
                }).catch((err) => {
                    ErrorMessage(err.response.data.msg)
                    if (err.response.status == 401) {
                        Logout();
                    }
                })
        } else {
            WaringMessage('confirm password does not match')
        }


    }



    //======================View User modal=============================
    const [viewUserShow, setViewUserModalShow] = useState(false);
    const [viewUserValue, setViewUserModalValue] = useState('');
    const viewUserModalClose = () => {
        setViewUserModalShow(false);
        setViewUserModalValue('')
    }
    const viewUserModalShow = (data) => {
        setViewUserModalShow(true);
        setViewUserModalValue(data)
    }



    // exportExcel
    const generatePdf = () => {
        exportPDF(selectedColumns, allUsers, "User")
    }
    const generateExcel = () => {
        exportExcel(selectedColumns, allUsers, "User")
    }
    const generatePrint = () => {
        printTable(selectedColumns, allUsers, "User")
    }


    const [allUsers, setAllUsers] = useState([])
    const [allSearchUser, setAllSearchUsers] = useState([])

    const handleKeyUpSearch = (e) => {
        const filteredItems = allUsers.filter((item) => {
            return item && item.name && item.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setAllSearchUsers(filteredItems);

    }

    const GetUser = () => {
        setLoading(true)
        PrivateAxios.get("user/user-list")
            .then((res) => {
                setLoading(false)
                setAllUsers(res.data.user)
                setAllSearchUsers(res.data.user)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetDepartMent = () => {
        PrivateAxios.get("all-department")
            .then((res) => {
                setDepartment(res.data.data)
            }).catch((err) => {
                if (err.response?.status == 401) {
                    Logout();
                }
            })
    }
    const GetRole = () => {
        PrivateAxios.get("get-role")
            .then((res) => {
                setRole(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetAllPermission = async () => {
        await PrivateAxios.get("all-permission")
            .then((res) => {
                setPermissionInitial(res.data.data);
            }).catch((err) => {

                console.log(err);
            })
    }

    useEffect(() => {
        GetUser();
        GetDepartMent();
        GetRole();
        GetAllPermission();
    }, [])


    //========================Create User=========================
    const [cretaeUserModel, setCretaeUserModel] = useState(false);
    const showCreateUser = () => {
        setCretaeUserModel(true)
    }
    const hideCreateUser = () => {
        setCretaeUserModel(false)
    }

    //=================Edit User============================
    const [editUserShow, setEditUserModalShow] = useState(false);
    const [editUserValue, setEditUserModalValue] = useState('');
    const editUserModalClose = () => {
        setEditUserModalShow(false);
    }
    const editUserModalShow = (data) => {
        setEditUserModalShow(true);
        setEditUserModalValue(data)
    }

    //---------------------------------------------User Image Upload------------------------------------------
    const [imageShowModel, setImageShowModel] = useState(false)
    const [user, setUser] = useState('')
    const [UserName, setUserName] = useState('')

    const CloseImageModal = () => {
        setImageShowModel(false)
        setUser('');
        setUserName('');
    }
    const ShowImageModal = (id) => {
        setUser(id);
        setUserName(User.find(data => data.id == id)?.name)
        setImageShowModel(true)
    }
    const [selectedImage, setSelectedImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [rotation, setRotation] = useState(0);
    const [image, setImage] = useState('')
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const uploadImage = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("file", image);
        PrivateAxiosFile.post(`user/change-image/${user}`, formData)
            .then((res) => {
                SuccessMessage(res.data.msg)
                CloseImageModal();
                GetUser();
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })

    }

    const handleScaleChange = (e) => {
        setScale(e.target.value);
    };
    const handlePositionChange = (direction) => {
        const step = 10; // Adjust the step size as needed
        setPosition((prevPosition) => {
            switch (direction) {
                case 'up':
                    return { ...prevPosition, top: prevPosition.top - step };
                case 'down':
                    return { ...prevPosition, top: prevPosition.top + step };
                case 'left':
                    return { ...prevPosition, left: prevPosition.left - step };
                case 'right':
                    return { ...prevPosition, left: prevPosition.left + step };
                default:
                    return prevPosition;
            }
        });
    };

    const handleRotationChange = (e) => {
        setRotation(e.target.value);
    };

    const handleUploadImage = () => {
        // Implement the upload logic here
        console.log('Image uploaded', selectedImage, scale, position, rotation);
        //onHide();
    };
    //
    //========================pending Task modal start===========================
    const [showPendingTaskModal, setShowPendingTaskModal] = useState(false);
    const [showPendingTask, setShowPendingTask] = useState('');
    const [showPendingChecksheet, setShowPendingChecksheet] = useState('');
    const [showPendingFms, setShowPendingFms] = useState('');
    const handleClosePendingTaskModal = () => {
        setShowPendingTaskModal(false);
        setShowPendingTask('')
        setShowPendingChecksheet('')
        setShowPendingFms('')
    }
    const handleShowPendingTaskModal = (data) => {
        setShowPendingTaskModal(true);
        setShowPendingTask(data.tasktracker.length > 0 ? data.tasktracker.filter(item => item.delegation_status_id == 1 || item.delegation_status_id == 2) : [])
        setShowPendingChecksheet(data.checksheet)
        setShowPendingFms(data.fms)
    }

    const TotlaLengthTask = (data) => {
        let taskNumber = data.tasktracker.length > 0 ? data.tasktracker.filter(item => item.delegation_status_id == 1 || item.delegation_status_id == 2) : [];
        let taskFms = data.fms.length;
        let checksheet = data.checksheet.length;
        return taskNumber.length + taskFms + checksheet;
    }

    const selectedColumns = [
        {
            name: 'Sl No.',
            selector: (row, index) => index + 1,
            minWidth: "80px",
            maxWidth: "80px"
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            width: "220px",
            cell: (row) => (
                <div className="profile-wrap">
                    <div className="exp-avtar bg-exp-green">
                        {
                            row && row.user_photo != null ?
                                < img className="prof-img" src={url + row.user_photo} alt="user" /> :
                                <span>{row && row.name != '' ? row.name.split(' ').length > 1 ? row.name.split(' ')[0].split('')[0] + row.name.split(' ')[1].split('')[0] : row.name.split('')[0] + row.name.split('')[1] : ""}</span>
                        }

                    </div>
                    <div className="ps-2 profile-name-wrap text-truncate">
                        <h5 className="profile-name text-nowrap text-truncate">{row && row.name}</h5>
                    </div>
                </div>

            ),
        },

        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
            width: "260px"
        },
        // {
        //     name: "Role",
        //     selector: (row) => "admin",
        //     sortable: true,
        //     reorder: true,
        //     maxWidth: "120px"
        // },
        {
            name: "Department",
            selector: (row) => row.depart && row.depart.title,
            sortable: true,
            reorder: true,
            maxWidth: "150px"
        },
        {
            name: "Phone Number",
            selector: (row) => row.phone_number,
            sortable: true,
            reorder: true,
            maxWidth: "180px"
        },
        {
            name: "Action",
            width: "200px",
            selector: (row) => '',
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => viewUserModalShow(row)}>
                            <i className="fi fi-rr-eye"></i>
                        </button>
                    </OverlayTrigger>
                    {MatchPermission(["Change Photo"]) ?
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Change Image
                                </Tooltip>
                            }
                        >
                            <button className="me-1 table-action-btn" onClick={() => ShowImageModal(row.id)}>
                                <i className="fi fi-rr-images"></i>
                            </button>
                        </OverlayTrigger> : ""
                    }
                    {MatchPermission(["Change Password"]) ?
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Change Password
                                </Tooltip>
                            }
                        >
                            <button className="me-1 table-action-btn" onClick={() => handleChangePasswordModalShow(row.id)}>
                                <i className="fi fi-rr-lock"></i>
                            </button>
                        </OverlayTrigger> : ""
                    }
                    {MatchPermission(["Edit User"]) ?
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Edit
                                </Tooltip>
                            }
                        >
                            <button className="me-1 table-action-btn" onClick={() => editUserModalShow(row)}>
                                <i className="fi fi-rr-pencil"></i>
                            </button>
                        </OverlayTrigger> : ""
                    }
                    {/* <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }
                    >
                        <button disabled={TotlaLengthTask(row) !== 0} className="me-1 table-action-btn" onClick={() => deleteModalShow(row)}>
                            <i className="fi fi-rr-trash text-exp-red"></i>
                        </button>
                    </OverlayTrigger> */}
                    {/* <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Pending Task
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn position-relative" onClick={() => handleShowPendingTaskModal(row)}>
                            {TotlaLengthTask(row) !== 0 ? <span className='pending-task-notification'>{TotlaLengthTask(row)}</span> : ""}
                            <i className="fi fi-sr-circle-p"></i>
                        </button>
                    </OverlayTrigger> */}

                </div>
            ),
        },
    ];

    //====================Delete modal=================================
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteUserID, setDeleteUserID] = useState('');
    const deleteModalClose = () => {
        setDeleteShow(false);
        setDeleteUserID('')
    }
    const deleteModalShow = (id) => {
        setDeleteShow(true);
        setDeleteUserID(id)
    }
    const DeleteUser = () => {
        PrivateAxios.delete(`user/delete-user/${deleteUserID}`)
            .then((res) => {
                setAllUsers(prevent => prevent.filter(item => item.id != deleteUserID))
                SuccessMessage(res.data.msg)
                deleteModalClose();
            }).catch((err) => {

            })
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const togglePasswordVisibilityNew = () => {
        setShowPasswordNew(!showPasswordNew);
    };
    const [showPasswordCon, setShowPasswordCon] = useState(false);
    const togglePasswordVisibilityCon = () => {
        setShowPasswordCon(!showPasswordCon);
    };


    return (

        <React.Fragment>
            {loading ? <Loader /> :
                <>
                    <div className="gthh-controller-bar">
                        <ul className="gth-controller-view-block">
                            <li>
                                <Link className="gth-controller-view-item active" to="/users">
                                    <i className="fi fi-rr-user-check me-2" />
                                    Active Users
                                </Link>
                            </li>
                            <li>
                                <Link className="gth-controller-view-item " to="/deleted-users">
                                    <i className="fi fi-rr-user-minus me-2" />
                                    Deleted Users
                                </Link>
                            </li>
                        </ul>
                        {/* <button className='btn btn-primary btn-sm' onClick={showCreateUser}><i className='fi fi-sr-add me-2 mt-2'></i>Add User</button> */}
                    </div>

                    <div className="p-4">
                        <div className='card'>
                            {/*<div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                                <h3 className="card-title">Users List</h3>
                                 <button className="btn btn-exp-green ms-auto me-0">
                                    <i className="fi fi-rr-user-add me-2"></i>Create User
                                </button> 
                            </div>*/}
                            <div className='card-body p-0'>
                                {!allUsers.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        <div className="table-view" >
                                            <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                <div className='table-button-group mb-3'>
                                                    <button className='btn table-export-btn' onClick={generateExcel} >
                                                        <i className="fi fi-rr-file-csv"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePdf}>
                                                        <i className="fi fi-rr-file-pdf"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePrint}>
                                                        <i className="fi fi-rr-print"></i>
                                                    </button>
                                                </div>
                                                <div className='d-flex align-items-center mb-3 ms-auto'>
                                                    <label className='mr-2 mb-0'>Search: </label>
                                                    <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={selectedColumns}
                                                data={allSearchUser}
                                                pagination={[5, 10, 25, 50]}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap checklist-table-striped'
                                            //customStyles={customStyles}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
            <>

                {/* create user */}
                <AddUser GetUser={GetUser} editUserShow={cretaeUserModel} editUserModalClose={hideCreateUser} department={department} role={role} />

                {/* User Image Upload Modal*/}
                <Modal id="userImageChange" show={imageShowModel} onHide={CloseImageModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title >{UserName && UserName}</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={uploadImage}>
                        <Modal.Body className="pb-1">
                            {/* <div className="form-group">
                                <div className="profile-image-cropping">
                                    <img
                                        className="user-img"
                                        src={selectedImage || process.env.PUBLIC_URL + 'assets/images/user-profile-pictures/user-common.jpg'}
                                        alt="user"
                                        style={{
                                            position: 'absolute',
                                            transform: `scale(${scale}) rotate(${rotation}deg)`,
                                            top: `${position.top}px`,
                                            left: `${position.left}px`,
                                        }}
                                    />
                                </div>
                            </div> */}
                            <div className="form-group mt-3">
                                <label className="form-label">Upload Image:</label>
                                <input type="file" required className="form-control" accept='.jpeg,.png,.jpg' onChange={handleImageChange} />
                            </div>
                            {/* <div className="form-group mt-3">
                                <label className="form-label">Scale:</label>
                                <input
                                    type="range"
                                    className="form-control-range"
                                    min="0.5"
                                    max="2"
                                    step="0.01"
                                    value={scale}
                                    onChange={handleScaleChange}
                                />
                            </div> */}
                            {/* <div className="form-group mt-3">
                                <label className="form-label">Rotate:</label>
                                <input
                                    type="range"
                                    className="form-control-range"
                                    min="0"
                                    max="360"
                                    step="1"
                                    value={rotation}
                                    onChange={handleRotationChange}
                                />
                            </div>
                            <div className="form-group mt-3 d-flex align-items-center">
                                <label className="form-label">Move:</label>
                                <div className="d-flex justify-content-center">
                                    <button onClick={() => handlePositionChange('left')} className='table-action-btn mx-1'>
                                        <i className="fi fi-rr-arrow-small-left"></i>
                                    </button>
                                    <button onClick={() => handlePositionChange('right')} className='table-action-btn mx-1'>
                                        <i className="fi fi-rr-arrow-small-right"></i>
                                    </button>
                                    <button onClick={() => handlePositionChange('up')} className='table-action-btn mx-1'>
                                        <i className="fi fi-rr-arrow-small-up"></i>
                                    </button>
                                    <button onClick={() => handlePositionChange('down')} className='table-action-btn mx-1'>
                                        <i className="fi fi-rr-arrow-small-down"></i>
                                    </button>
                                </div>
                            </div> */}
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center'>
                            <button type='button' className='btn btn-secondary' onClick={CloseImageModal}>
                                Cancel
                            </button>
                            <button className='btn btn-exp-green' type='submit'>
                                Update
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/* User Image Upload Modal End*/}

                {/* Change Password Modal Start */}
                <Modal id="UserChangePassword" show={changePasswordShow} onHide={handleChangePasswordModalClose} backdrop="static" keyboard={false} centered >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Change Password</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={UpdatePassword}>
                        <Modal.Body className='pb-1'>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="remarks" className="form-label">Old Password <span className="text-exp-red">*</span></label>
                                        {/* <input type="password" required value={passwordData.oldPassword} className="form-control" placeholder="Enter Old Password" onChange={(e) => setPasswordData({ ...passwordData, oldPassword: e.target.value })} /> */}
                                        <div className='input-group'>
                                            <input className={"form-control"} value={passwordData.oldPassword} type={showPassword ? "text" : "password"} name="password" placeholder="Enter Old Password" onChange={(e) => setPasswordData({ ...passwordData, oldPassword: e.target.value })} />
                                            <div className="input-group-text bg-white">
                                                <i className={`bi ${showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={togglePasswordVisibility} style={{ marginRight: 5, cursor: 'pointer', color: '#777' }} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="remarks" className="form-label">New Password <span className="text-exp-red">*</span></label>
                                        <div className='input-group'>
                                            <input type={showPasswordNew ? "text" : "password"} required value={passwordData.newPassword} className="form-control" placeholder="Enter New Password" onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })} />
                                            <div className="input-group-text bg-white">
                                                <i className={`bi ${showPasswordNew ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={togglePasswordVisibilityNew} style={{ marginRight: 5, cursor: 'pointer', color: '#777' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="remarks" className="form-label">Confirm Password <span className="text-exp-red">*</span></label>
                                        <div className='input-group'>
                                            <input type={showPasswordCon ? "text" : "password"} required className="form-control" placeholder="Re-enter Password" onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })} />
                                            <div className="input-group-text bg-white">
                                                <i className={`bi ${showPasswordCon ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={togglePasswordVisibilityCon} style={{ marginRight: 5, cursor: 'pointer', color: '#777' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type='submit' className='btn btn-exp-green'>
                                Confirm
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
                {/* Change Password Modal End */}
                {/* Delete modal start */}
                <DeleteModel deleteShow={deleteShow} deleteModalClose={deleteModalClose} deleteAction={DeleteUser} />
                {/* Delete modal end */}
                {/* Edit User Modal Start */}
                <EditUser permissionInitial={permissionInitial} editUserValue={editUserValue} editUserShow={editUserShow} editUserModalClose={editUserModalClose} department={department} role={role} GetUser={GetUser} />

                {/* Edit User Modal End */}
                {/* View User Modal Start */}
                <UserDetails viewUserShow={viewUserShow} viewUserModalClose={viewUserModalClose} data={viewUserValue} isDelete={1} role={role} permissionInitial={permissionInitial} />
                {/* View User Modal End */}
                {/* Pending Task Modal Start */}
                <Modal id="pending-task-modal" show={showPendingTaskModal} onHide={handleClosePendingTaskModal} backdrop="static" keyboard={false} centered size="lg">
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">List of Pending Task</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="nav nav-tabs gth-tabs" id="pendingTaskTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#pendingTask" type="button" role="tab" aria-controls="pendingTask" aria-selected="true" >
                                    <i className="fi fi-rr-to-do-alt me-1"></i>Task
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#pendingChecksheet" type="button" role="tab" aria-controls="pendingChecksheet" aria-selected="false" >
                                    <i className="fi fi-rr-to-do-alt me-1"></i>Checksheet
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#pendingFlow" type="button" role="tab" aria-controls="pendingFlow" aria-selected="false">
                                    <i className="fi fi-rr-workflow me-1"></i>Flow
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#pendingWorkfloStructure" type="button" role="tab" aria-controls="pendingWorkfloStructure" aria-selected="false">
                                    <i className="fi fi-rr-workflow me-1"></i>Work Flow Structure
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content pt-0">
                            <div className="tab-pane active" id="pendingTask" role="tabpanel"  >
                                <div className='card shadow-none'>
                                    <div className='card-body p-0'>
                                        <div className='pending-list-wrap'>
                                            <div className="table-responsive fixed-table-wrapper">
                                                <table className="table table-striped mb-0 th-border-top-0 fixedTable-head">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 100 }}><span className='text-nowrap'>Sl. No.</span></th>
                                                            <th><h6 className="mb-0 text-nowrap">Pending Tasks</h6></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {showPendingTask.length > 0 ?
                                                            showPendingTask.map(((item, i) => (
                                                                <tr key={item.id}>
                                                                    <td style={{ width: 100 }}>{i + 1}</td>
                                                                    <td>{item.name}</td>
                                                                </tr>))) : ""
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="pendingChecksheet" role="tabpanel">
                                <div className='card shadow-none'>
                                    <div className='card-body p-0'>
                                        <div className='pending-list-wrap'>
                                            <div className="table-responsive fixed-table-wrapper">
                                                <table className="table table-striped mb-0 th-border-top-0 fixedTable-head">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 100 }}><span className='text-nowrap'>Sl. No.</span></th>
                                                            <th><h6 className="mb-0 text-nowrap">Pending Checksheet</h6></th>
                                                            <th>Planned Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            showPendingChecksheet.length > 0 ?
                                                                showPendingChecksheet.map((item, i) => (
                                                                    <tr key={item.id}>
                                                                        <td style={{ width: 100 }}>{i + 1}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{moment(item.issue_date).format("DD-MM-YYYY")}</td>
                                                                    </tr>)) : ""
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="pendingFlow" role="tabpanel">
                                <div className='card shadow-none'>
                                    <div className='card-body p-0'>
                                        <div className='pending-list-wrap'>
                                            <div className="table-responsive fixed-table-wrapper">
                                                <table className="table table-striped mb-0 th-border-top-0 fixedTable-head">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 100 }}><span className='text-nowrap'>Sl. No.</span></th>
                                                            <th><h6 className="mb-0 text-nowrap">Pending Flow</h6></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            showPendingFms.length > 0 ?
                                                                showPendingFms.map((item, i) => (
                                                                    <tr key={item.id}>
                                                                        <td style={{ width: 100 }}>{i + 1}</td>
                                                                        <td>
                                                                            <div>
                                                                                {item.fms.name}: {item.name}<br />
                                                                            </div>
                                                                        </td>
                                                                    </tr>)) : ""
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="pendingWorkfloStructure" role="tabpanel">
                                <div className='card shadow-none'>
                                    <div className='card-body p-0'>
                                        <div className='pending-list-wrap'>
                                            <div className="table-responsive fixed-table-wrapper">
                                                <table className="table table-striped mb-0 th-border-top-0 fixedTable-head">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 100 }}><span className='text-nowrap'>Sl. No.</span></th>
                                                            <th>Process</th>
                                                            <th >Step</th>
                                                            <th style={{ width: 250 }}>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: 100 }}>1</td>
                                                            <td>
                                                                <div>
                                                                    Lead To Order Process
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    Prepare Draft
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="workflow-details-wrap">
                                                                    <p>
                                                                        Interview
                                                                    </p>
                                                                    <p>
                                                                        Candidate Name: <strong>Tamal Barui</strong>
                                                                    </p>
                                                                    <p>
                                                                        Contact: <strong>+9123618202</strong>
                                                                    </p>
                                                                    <p>
                                                                        Email: <strong>tbjgbs@gmail.com</strong>
                                                                    </p>
                                                                    <p>
                                                                        Qualification: <strong>Graduate</strong>
                                                                    </p>
                                                                    <p>
                                                                        Post Applied: <strong>MIS</strong>
                                                                    </p>
                                                                    <p>
                                                                        Experience: <strong>Experienced</strong>
                                                                    </p>
                                                                    <p>
                                                                        CV: <a href="#"><strong><i className="fi fi-rr-download me-2"></i>Download</strong></a>
                                                                    </p>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: 100 }}>2</td>
                                                            <td>
                                                                <div>
                                                                    Lead To Order Process
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    Prepare Draft
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="workflow-details-wrap">
                                                                    <p>
                                                                        Interview
                                                                    </p>
                                                                    <p>
                                                                        Candidate Name: <strong>Tamal Barui</strong>
                                                                    </p>
                                                                    <p>
                                                                        Contact: <strong>+9123618202</strong>
                                                                    </p>
                                                                    <p>
                                                                        Email: <strong>tbjgbs@gmail.com</strong>
                                                                    </p>
                                                                    <p>
                                                                        Qualification: <strong>Graduate</strong>
                                                                    </p>
                                                                    <p>
                                                                        Post Applied: <strong>MIS</strong>
                                                                    </p>
                                                                    <p>
                                                                        Experience: <strong>Experienced</strong>
                                                                    </p>
                                                                    <p>
                                                                        CV: <a href="#"><strong><i className="fi fi-rr-download me-2"></i>Download</strong></a>
                                                                    </p>

                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/* Pending Task Modal End */}

            </>

        </React.Fragment >

    )
}

export default Users;
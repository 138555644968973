// import React, { useEffect, useState } from 'react'
// import { Chart as ChartJS, ArcElement, Legend, Colors } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';

// ChartJS.register(ArcElement, Legend);

// const optionsWorkflow = {
//     responsive: true,
//     plugins: {
//         legend: {
//             display: true,
//             position: 'bottom',
//             labels: {
//                 color: '#9e9e9e'
//             },
//         },
//     },
//     cutout: '60%',
// }

// function WorkflowChart({ workFlowChart }) {

//     console.log(workFlowChart);


//     const totalTasks = (workFlowChart?.todo || 0) +
//         (workFlowChart?.inprogress || 0) +
//         (workFlowChart?.complete || 0) +
//         (workFlowChart?.tododelay || 0) +
//         (workFlowChart?.inprogressDelay || 0) +
//         (workFlowChart?.completeDelay || 0);

//     const hasData = totalTasks > 0;

//     const data = {
//         labels: ['Todo', 'In Progress', 'Completed', 'Todo Delay', 'In Progress Delay', 'Delay Completed'],
//         datasets: [
//             {
//                 data: [workFlowChart && workFlowChart.todo, workFlowChart && workFlowChart.inprogress, workFlowChart && workFlowChart.complete, workFlowChart && workFlowChart.tododelay, workFlowChart && workFlowChart.inprogressDelay, workFlowChart && workFlowChart.completeDelay],
//                 backgroundColor: [
//                     // 'rgb(233, 235, 238)',
//                     'rgb(127, 127, 127)',
//                     'rgb(89, 71, 208)',
//                     'rgb(63, 213, 52)',
//                     'rgb(255, 230, 43)',
//                     'rgb(232, 143, 41)',
//                     'rgb(244, 27, 27)',
//                 ],
//                 borderWidth: 0,
//             },
//         ],
//     };

//     //dark theme image change
//     const [isDarkTheme, setIsDarkTheme] = useState(false);

//     useEffect(() => {
//         // Check for the theme on initial load
//         const currentTheme = document.body.classList.contains("dark-theme");
//         setIsDarkTheme(currentTheme);

//         // Listen for changes to the body's class (if toggling happens outside this component)
//         const observer = new MutationObserver(() => {
//             const updatedTheme = document.body.classList.contains("dark-theme");
//             setIsDarkTheme(updatedTheme);
//         });

//         observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

//         // Cleanup observer on component unmount
//         return () => observer.disconnect();
//     }, []);
//     //dark theme image change end

//     return (
//         <>
//             <div className='card text-center graph-card h-100 mb-0'>
//                 <div className='card-body'>
//                     <h5 className='card-title mb-2'>Work Flow</h5>
//                     {hasData ?
//                         <div className='chart-wrap'>
//                             <Doughnut options={optionsWorkflow} data={data} />
//                         </div> :
//                         <div className='chart-wrap-nodata'>
//                             <img
//                                 src={
//                                     isDarkTheme
//                                         ? `${process.env.PUBLIC_URL}/assets/images/graph-nodata-dark.png`
//                                         : `${process.env.PUBLIC_URL}/assets/images/graph-nodata.png`
//                                 }
//                                 className="img-fluid"
//                                 alt="no-data"
//                             />
//                         </div>

//                     }
//                 </div>
//             </div>
//         </>
//     )
// }

// export default WorkflowChart



import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import ChartPlaceholder from '../../component/ChartPlaceholder';

ChartJS.register(ArcElement, Tooltip, Legend);

const optionsWorkflow = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                color: '#9e9e9e',
                align: 'start', // Left-align the labels
                usePointStyle: true,
                padding: 10,
            },
        },
    },
    cutout: '60%',
};

function WorkflowChart({ workFlowChart, Loading }) {

    console.log(workFlowChart,"flow");
    
    const chartRef = useRef(null);
    const navigate = useNavigate();

    const totalTasks = (workFlowChart?.todo || 0) +
        (workFlowChart?.inprogress || 0) +
        (workFlowChart?.complete || 0) +
        (workFlowChart?.tododelay || 0) +
        (workFlowChart?.inprogressDelay || 0) +
        (workFlowChart?.completeDelay || 0);

    const hasData = totalTasks > 0;

    const data = {
        labels: ['Todo', 'In Progress', 'Completed', 'Todo Delay', 'In Progress Delay', 'Delay Completed'],
        datasets: [
            {
                data: [
                    workFlowChart?.todo || 0,
                    workFlowChart?.inprogress || 0,
                    workFlowChart?.complete || 0,
                    workFlowChart?.tododelay || 0,
                    workFlowChart?.inprogressDelay || 0,
                    workFlowChart?.completeDelay || 0,
                ],
                backgroundColor: [
                    'rgb(127, 127, 127)',
                    'rgb(89, 71, 208)',
                    'rgb(63, 213, 52)',
                    'rgb(255, 230, 43)',
                    'rgb(232, 143, 41)',
                    'rgb(244, 27, 27)',
                ],
                borderWidth: 0,
            },
        ],
    };

    const [isDarkTheme, setIsDarkTheme] = useState(false);

    useEffect(() => {
        const currentTheme = document.body.classList.contains('dark-theme');
        setIsDarkTheme(currentTheme);

        const observer = new MutationObserver(() => {
            const updatedTheme = document.body.classList.contains('dark-theme');
            setIsDarkTheme(updatedTheme);
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) return;

        chart.options.onClick = (event, elements) => {
            if (elements.length > 0) {
                const index = elements[0].index;
                const label = data.labels[index];

                // Navigate based on the clicked label
                switch (label) {
                    case 'Todo':
                        navigate('/flow');
                        break;
                    case 'In Progress':
                        navigate('/flow');
                        break;
                    case 'Completed':
                        navigate('/flow');
                        break;
                    case 'Todo Delay':
                        navigate('/flow');
                        break;
                    case 'In Progress Delay':
                        navigate('/flow');
                        break;
                    case 'Delay Completed':
                        navigate('/flow');
                        break;
                    default:
                        break;
                }
            }
        };
    }, [navigate, data]);

    return (
        <div className="card text-center graph-card h-100 mb-0">
            <div className="card-body">
                <h5 className="card-title mb-2">Workflow</h5>
                {Loading ?
                    <ChartPlaceholder /> :
                    <>
                        {hasData ? (
                            <div className="chart-wrap">
                                <Doughnut ref={chartRef} options={optionsWorkflow} data={data} />
                            </div>
                        ) : (
                            <div className="chart-wrap-nodata">
                                <img
                                    src={
                                        isDarkTheme
                                            ? `${process.env.PUBLIC_URL}/assets/images/graph-nodata-dark.png`
                                            : `${process.env.PUBLIC_URL}/assets/images/graph-nodata.png`
                                    }
                                    className="img-fluid"
                                    alt="no-data"
                                />
                            </div>
                        )}
                    </>
                }
            </div>
        </div>
    );
}

export default WorkflowChart;




import React, { useEffect, useState } from 'react'
import { PrivateAxios } from '../../environment/AxiosInstance'
import { SuccessMessage } from '../../environment/ToastMessage';

function NotificationSetting() {
  const [data, setData] = useState('');
  const GetNotification = async () => {
    PrivateAxios.get("get-notification")
      .then((res) => {
        // console.log(res.data.data);
        setData(res.data.data);
      }).catch((err) => {
        console.log(err);
      })
  }
  useEffect(() => {
    GetNotification()
  }, [])

  const handleChange = (e) => {
    if (e.target.checked) {
      setData({ ...data, [e.target.name]: e.target.value });
    } else {
      setData({ ...data, [e.target.name]: null });
    }
  }
  const submit = (e) => {
    e.preventDefault();
    PrivateAxios.post('edit-notification', data)
      .then((res) => {
        SuccessMessage(res.data.message)
      }).catch((err) => {
        console.log(err);
      })
  }
  return (
    <div id="fms_list_section" className="p-4">
      <div className="row">
        <div className="col-12">
          <div className="card overflow-hidden">
            <form onSubmit={submit}>
              <div className="card-body p-0">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th nowrap>Function Name</th>
                      <th nowrap className="text-center">Email</th>
                      <th nowrap className="text-center">Whatsapp</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p className='mb-0'>Work Flow Notification</p></td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="fms_email_notification" className="form-check-input" type="checkbox" defaultValue={1} checked={data && data.fms_email_notification == 1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="fms_whatsapp_notification" className="form-check-input" type="checkbox" checked={data && data.fms_whatsapp_notification == 1} defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td><p className='mb-0'>Checksheet Notification</p></td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="checklist_email_notification" checked={data && data.checklist_email_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="checklist_whatsapp_notification" checked={data && data.checklist_whatsapp_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td><p className='mb-0'>Task Tracker Notification</p></td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="delegation_email_notification" checked={data && data.delegation_email_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="delegation_whatsapp_notification" checked={data && data.delegation_whatsapp_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td><p className='mb-0'>Help Ticket Notification</p></td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="ticket_email_notification" checked={data && data.ticket_email_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="ticket_whatsapp_notification" checked={data && data.ticket_whatsapp_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td><p className='mb-0'>User Notification</p></td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="user_email_notification" checked={data && data.user_email_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          {/* <input name="user_whatsapp_notification" checked={data && data.user_whatsapp_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span> */}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td><p className='mb-0'>Auditor Notification</p></td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="auditor_email_notification" checked={data && data.auditor_email_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td className="text-center">
                        <label class="custom-checkbox mb-0">
                          &nbsp;
                          <input name="auditor_whatsapp_notification" checked={data && data.auditor_whatsapp_notification == 1} className="form-check-input" type="checkbox" defaultValue={1} onChange={handleChange} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <input type="submit" name="submit-button" className="btn btn-exp-green" value="Save" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default NotificationSetting
import React, { useContext, useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import Select from 'react-select'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button, Dropdown, DropdownButton, OverlayTrigger, Popover, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { setHours, setMinutes } from 'date-fns';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-bootstrap/Modal';
import Loader from './landing/loder/Loader';
import { SlideshowLightbox } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TaskBar from './task/TaskBar';
ChartJS.register(ArcElement, Legend);


function DashboardCalendar() {
    // for assign to me start 
    const [selectedValue, setSelectedValue] = useState('Assign to Me');
    const handleSelect = (eventKey) => {
        setSelectedValue(eventKey);
    };

    const events = [
        {
            title: 'Work Flow',
            start: '2024-07-17',
            backgroundColor: 'rgb(255, 252, 218)',
            borderColor: 'rgb(255, 193, 7)',
        },
        {
            title: 'Task Tracker',
            start: '2024-07-18',
            backgroundColor: 'rgb(243, 229, 251)',
            borderColor: 'rgb(131, 42, 204)',
        },
        {
            title: 'Checksheet',
            start: '2024-07-19',
            end: '2024-07-20',
            backgroundColor: 'rgb(227, 255, 254)',
            borderColor: 'rgb(0, 133, 126)',
        }
    ];
    const renderEventContent = (eventInfo) => {
        return (
            <div className="custom-calendar-event-content">
                <div className="custom-calendar-event-title">{eventInfo.event.title}</div>
            </div>
        );
    };

    // for task details modal
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const handleCloseTaskDetails = () => setShowTaskDetails(false);
    const handleShowTaskDetails = () => setShowTaskDetails(true);
    //task done modal
    const [taskDoneShow, setTaskDoneShow] = useState(false);
    const taskDoneModalClose = () => setTaskDoneShow(false);
    const taskDoneModalShow = () => setTaskDoneShow(true);
    //
    //calendar task system controller modal
    const [showTaskSystemControllerModal, setTaskSystemControllerModal] = useState(false);
    const handleTaskSystemControllerModalClose = () => setTaskSystemControllerModal(false);
    const handleTaskSystemControllerModal = () => setTaskSystemControllerModal(true);
    //
    //calendar task system controller modal
    const [showTaskDoerModal, setTaskDoerModal] = useState(false);
    const handleTaskDoerModalClose = () => setTaskDoerModal(false);
    const handleTaskDoerModal = () => setTaskDoerModal(true);
    //calendar Checksheet system controller modal
    const [showChecksheetSystemControllerModal, setChecksheetSystemControllerModal] = useState(false);
    const handleChecksheetSystemControllerModalClose = () => setChecksheetSystemControllerModal(false);
    const handleChecksheetSystemControllerModal = () => setChecksheetSystemControllerModal(true);
    //calendar Checksheet Doer modal
    const [showChecksheetDoerModal, setChecksheetDoerModal] = useState(false);
    const handleChecksheetDoerModalClose = () => setChecksheetDoerModal(false);
    const handleChecksheetDoerModal = () => setChecksheetDoerModal(true);
    //Flow Submit System Controller modal
    const [flowSubmitModalSystemControllerShow, setFlowSubmitModalSystemControllerShow] = useState(false);
    const flowSubmitModalSystemControllerClose = () => setFlowSubmitModalSystemControllerShow(false);
    const openFlowSubmitModalSystemControllerShow = () => setFlowSubmitModalSystemControllerShow(true);
    //Flow Submit Doer modal
    const [flowSubmitDoerModalShow, setFlowSubmitDoerModalShow] = useState(false);
    const flowSubmitDoerModalClose = () => setFlowSubmitDoerModalShow(false);
    const openFlowSubmitDoerModalShow = () => setFlowSubmitDoerModalShow(true);
    //Delete modal
    const [deleteShow, setDeleteShow] = useState(false);
    const deleteModalClose = () => setDeleteShow(false);
    const deleteModalShow = () => setDeleteShow(true);

    const [startDate, setStartDate] = useState(new Date());
    const handleDateChange = (date) => {
        setStartDate(date);
    };
    // for attachment list action
    const attachmentListAction = (
        <Popover id="statusChange" className="action-wrap">
            <div className="action-list">
                <div className="action-list-item">
                    <i class="fi fi-sr-download me-2"></i>
                    <span>Download</span>
                </div>
                <div className="action-list-item">
                    <i class="fi fi-rr-trash text-exp-red me-2"></i>
                    <span>Delete</span>
                </div>
            </div>
        </Popover>
    );
    // for attachment list action end


    return (
        <React.Fragment>
            <div className="gthh-controller-bar">
                <ul className="gth-controller-view-block">
                    <TaskBar/>
                </ul>
                <div className="gth-assign-dropdown">
                    <Dropdown onSelect={handleSelect} align="end">
                        <Dropdown.Toggle className="btn-exp-primary assignSelect" id="dropdown-assign">
                            <span className="text-truncate">{selectedValue}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='slideIn dropdown-animate'>
                            <Dropdown.Item eventKey="Assign to Me" href="#">
                                Assign to Me
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Assign by Me" href="#">
                                Assign by Me
                            </Dropdown.Item>
                            {/* <Dropdown.Item eventKey="Assign to All" href="#">
                                Assign to All
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="gth-dashboard-v2-process-wrap pt-4">
                <div className='card'>
                    <div className='card-body calender-wrap'>
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            weekends={true}
                            events={events}
                            selectable
                            style={{ height: '100vh' }}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay',
                            }}
                            eventClick={openFlowSubmitDoerModalShow}
                            eventContent={renderEventContent}
                        />
                    </div>
                </div>
            </div>

            {/* calendar task system controller modal start */}
            <form>
                <Modal id="calendar-task-systm-contlr" show={showTaskSystemControllerModal} onHide={handleTaskSystemControllerModalClose} backdrop="static" centered size="xl" className="task-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="task-title-edit-wrap">
                                <input value="Project 1 Sample View Task 2" className="task-name" />
                                <span className="task-edit"><i className="fi fi-rr-pencil"></i></span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="task-details-modal-body-wrap">
                        <div className="task-details-modal-body">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Assignee</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-green">
                                                            <span>JP</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="assignee-wrapper">
                                                        <div className="search-assignee">
                                                            <span className="search-assignee-icon"><i class="fi fi-rr-search"></i></span><input type="text" className="form-control" placeholder="Search or enter email.." />
                                                        </div>
                                                        <div className="user-list-wrap">
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-green">
                                                                        <span>JP</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Subhadeep Chowdhury</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-blue">
                                                                        <span>MS</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Gopal Mukherjee</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Doer</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-yellow">
                                                            <span>SP</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="assignee-wrapper">
                                                        <div className="search-assignee">
                                                            <span className="search-assignee-icon"><i class="fi fi-rr-search"></i></span><input type="text" className="form-control" placeholder="Search or enter email.." />
                                                        </div>
                                                        <div className="user-list-wrap">
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-green">
                                                                        <span>JP</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Subhadeep Chowdhury</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-blue">
                                                                        <span>MS</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Gopal Mukherjee</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-calendar me-2"></i>
                                                <span>Planned Date</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <span className="planned-date-txt">
                                                        {/* {planDate.toDateString()} */}
                                                        {format(startDate, "d MMMM, yyyy")}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="calendar-popup">
                                                        <div className="calendar-wrap">
                                                            {/* <Calendar
                                                                onChange={onDateChange}
                                                                value={planDate}
                                                            /> */}
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={handleDateChange}
                                                                //showTimeSelect
                                                                inline
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                            />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-flag-alt me-2"></i>
                                                <span>Priority</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="priority-set urgent">
                                                        <i class="fi fi-sr-flag-alt mr-2"></i>
                                                        <span>Urgent</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="pariority-wrap">
                                                        <div className="pariority-list">
                                                            <div className="priority-set urgent priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>Urgent</span>
                                                            </div>
                                                            <div className="priority-set high priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>High</span>
                                                            </div>
                                                            <div className="priority-set normal priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>Normal</span>
                                                            </div>
                                                            <div className="priority-set low priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>Low</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                <span>Status</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="status-item inprogress">
                                                        <i className="fi fi-rr-process me-2"></i>
                                                        <span>In Progress</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="status-wrap">
                                                        <div className="status-list">
                                                            <div className="status-item todo status-list-item">
                                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                                <span>To Do</span>
                                                            </div>
                                                            <div className="status-item inprogress status-list-item">
                                                                <i className="fi fi-rr-process me-2"></i>
                                                                <span>In Progress</span>
                                                            </div>
                                                            <div className="status-item completed status-list-item">
                                                                <i className="fi fi-rs-check-circle me-2"></i>
                                                                <span>Completed</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="attachment-header">
                                        <h6>Attachments</h6>
                                        <div className="attachment-buttons">

                                            <Dropdown className="gth-modal-attachment-drpdown" align="end">
                                                <Dropdown.Toggle id="status" className="gth-action-button ms-2 ">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Add Attachments
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i class="fi fi-br-plus"></i>
                                                    </OverlayTrigger>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="attachment-wrap">
                                                    <div className="attachment-list">
                                                        <div className="attachment-list-item custom-file-upload">
                                                            <i class="fi fi-rr-clip me-2"></i>
                                                            <button class="upload-btn">Upload File</button>
                                                            <input type="file" name="myfile" className="input-upload" />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* for grid view */}
                                    <div className="card shadow-none border grid-files mt-2 mb-0">
                                        <div className="card-header bg-exp-primary-grey-light-1">
                                            <h6>Files</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="gth-attachment-body">
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="action-btn">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/login-bg-bk.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="gth-action-button">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/visitor.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    {/* for grid view end*/}

                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="description">
                                <h6>Description</h6>
                                <p className="mb-0 text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* calendar task system controller modal end */}
            {/* calendar task doer modal start */}
            <form>
                <Modal id="calendar-task-doer" show={showTaskDoerModal} onHide={handleTaskDoerModalClose} backdrop="static" centered size="xl" className="task-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Project 1 Sample View Task 2
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="task-details-modal-body-wrap">
                        <div className="task-details-modal-body">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Assignee</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-green">
                                                        <span>JP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Doer</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-yellow">
                                                        <span>SP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-calendar me-2"></i>
                                                <span>Planned Date</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <span className="planned-date-txt">
                                                    {format(startDate, "d MMMM, yyyy")}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-flag-alt me-2"></i>
                                                <span>Priority</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="priority-set urgent">
                                                    <i class="fi fi-sr-flag-alt mr-2"></i>
                                                    <span>Urgent</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                <span>Status</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="status-item inprogress">
                                                        <i className="fi fi-rr-process me-2"></i>
                                                        <span>In Progress</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="status-wrap">
                                                        <div className="status-list">
                                                            <div className="status-item todo status-list-item">
                                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                                <span>To Do</span>
                                                            </div>
                                                            <div className="status-item inprogress status-list-item">
                                                                <i className="fi fi-rr-process me-2"></i>
                                                                <span>In Progress</span>
                                                            </div>
                                                            <div className="status-item completed status-list-item" onClick={taskDoneModalShow}>
                                                                <i className="fi fi-rs-check-circle me-2"></i>
                                                                <span>Completed</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="attachment-header">
                                        <h6>Attachments</h6>
                                        <div className="attachment-buttons">

                                            <Dropdown className="gth-modal-attachment-drpdown" align="end">
                                                <Dropdown.Toggle id="status" className="gth-action-button ms-2 ">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Add Attachments
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i class="fi fi-br-plus"></i>
                                                    </OverlayTrigger>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="attachment-wrap">
                                                    <div className="attachment-list">
                                                        <div className="attachment-list-item custom-file-upload">
                                                            <i class="fi fi-rr-clip me-2"></i>
                                                            <button class="upload-btn">Upload File</button>
                                                            <input type="file" name="myfile" className="input-upload" />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* for grid view */}
                                    <div className="card shadow-none border grid-files mt-2 mb-0">
                                        <div className="card-header bg-exp-primary-grey-light-1">
                                            <h6>Files</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="gth-attachment-body">
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="action-btn">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/login-bg-bk.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="gth-action-button">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/visitor.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    {/* for grid view end*/}

                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="description">
                                <h6>Description</h6>
                                <p className="mb-0 text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                            <hr className="my-3" />
                            <div className="remarks">
                                <h6>Remarks</h6>
                                <p className="mb-0 text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                                <div className="remarks-attachment-wrap">
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-file-pdf"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Sample.pdf
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Sample.pdf
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-file-word"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.docx
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.docx
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-file-excel"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.xlsx
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.xlsx
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-picture"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.png
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.png
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-document"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.txt
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.txt
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* calendar task doer modal end */}
            {/* calendar checksheet system controller modal start */}
            <form>
                <Modal id="calendar-checksheet-systm-contlr" show={showChecksheetSystemControllerModal} onHide={handleChecksheetSystemControllerModalClose} backdrop="static" centered size="xl" className="task-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="task-title-edit-wrap">
                                <input value="Project 1 Sample View Task 2" className="task-name" />
                                <span className="task-edit"><i className="fi fi-rr-pencil"></i></span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="task-details-modal-body-wrap">
                        <div className="task-details-modal-body">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Assignee</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-green">
                                                            <span>JP</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="assignee-wrapper">
                                                        <div className="search-assignee">
                                                            <span className="search-assignee-icon"><i class="fi fi-rr-search"></i></span><input type="text" className="form-control" placeholder="Search or enter email.." />
                                                        </div>
                                                        <div className="user-list-wrap">
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-green">
                                                                        <span>JP</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Subhadeep Chowdhury</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-blue">
                                                                        <span>MS</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Gopal Mukherjee</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Doer</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-yellow">
                                                            <span>SP</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="assignee-wrapper">
                                                        <div className="search-assignee">
                                                            <span className="search-assignee-icon"><i class="fi fi-rr-search"></i></span><input type="text" className="form-control" placeholder="Search or enter email.." />
                                                        </div>
                                                        <div className="user-list-wrap">
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-green">
                                                                        <span>JP</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Subhadeep Chowdhury</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i class="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-blue">
                                                                        <span>MS</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Gopal Mukherjee</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-calendar me-2"></i>
                                                <span>Planned Date</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <span className="planned-date-txt">
                                                        {/* {planDate.toDateString()} */}
                                                        {format(startDate, "d MMMM, yyyy")}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="calendar-popup">
                                                        <div className="calendar-wrap">
                                                            {/* <Calendar
                                                                onChange={onDateChange}
                                                                value={planDate}
                                                            /> */}
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={handleDateChange}
                                                                //showTimeSelect
                                                                inline
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                            />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-flag-alt me-2"></i>
                                                <span>Priority</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="priority-set urgent">
                                                        <i class="fi fi-sr-flag-alt mr-2"></i>
                                                        <span>Urgent</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="pariority-wrap">
                                                        <div className="pariority-list">
                                                            <div className="priority-set urgent priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>Urgent</span>
                                                            </div>
                                                            <div className="priority-set high priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>High</span>
                                                            </div>
                                                            <div className="priority-set normal priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>Normal</span>
                                                            </div>
                                                            <div className="priority-set low priority-list-item">
                                                                <i class="fi fi-sr-flag-alt mr-2"></i>
                                                                <span>Low</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                <span>Status</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="status-item inprogress">
                                                        <i className="fi fi-rr-process me-2"></i>
                                                        <span>In Progress</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="status-wrap">
                                                        <div className="status-list">
                                                            <div className="status-item todo status-list-item">
                                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                                <span>To Do</span>
                                                            </div>
                                                            <div className="status-item inprogress status-list-item">
                                                                <i className="fi fi-rr-process me-2"></i>
                                                                <span>In Progress</span>
                                                            </div>
                                                            <div className="status-item completed status-list-item">
                                                                <i className="fi fi-rs-check-circle me-2"></i>
                                                                <span>Completed</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item">
                                                <span>Mode</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="status-item inprogress">
                                                        <span>Last Date of Every Month</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="status-wrap">
                                                        <div className="status-list">
                                                            <div className="status-item status-list-item">
                                                                <span>Yearly</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Halfyearly</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Quarterly</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Last Date of Every Month</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Monthly</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Weekly</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Daily</span>
                                                            </div>
                                                            <div className="status-item status-list-item">
                                                                <span>Weekly</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-user me-2"></i>
                                                <span>Notify To <br />(if not done)</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="profile-wrap">
                                                        <div className="exp-avtar bg-exp-yellow">
                                                            <span>SP</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="assignee-wrapper">
                                                        <div className="search-assignee">
                                                            <span className="search-assignee-icon"><i className="fi fi-rr-search"></i></span><input type="text" className="form-control" placeholder="Search or enter email.." />
                                                        </div>
                                                        <div className="user-list-wrap">
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i className="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-green">
                                                                        <span>JP</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Subhadeep Chowdhury</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-light">
                                                                        <i className="fi fi-rr-user user-icon"></i>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar bg-exp-blue">
                                                                        <span>MS</span>
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="user-item">
                                                                <div className="profile-wrap">
                                                                    <div className="exp-avtar">
                                                                        <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                                                    </div>
                                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                                        <h5 className="profile-name text-truncate">Gopal Mukherjee</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow-none border mt-3 overflow-hidden">
                                <div className="card-header bg-exp-primary-grey-light-1 d-flex flex-wrap align-items-center justify-content-between">
                                    <h6 className="mb-0 me-3">Set Reminder</h6>
                                    <label className="custom-switch my-1 ms-auto" >
                                        <input type="checkbox" />
                                        <div className="switch-slider switch-round" />
                                    </label>
                                </div>

                                <div className="card-body pb-1">
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Reminder Mode</label>
                                                <div className='custom-select-wrap'>
                                                    <input type='text' value="Daily" className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Day Before </label>
                                                <input type='number' className='form-control' placeholder='Day Before' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className='col-lg-12'>
                                <div className='card shadow-none border overflow-hidden bg-light-blue'>
                                    <div className='card-body p-0'>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>

                                                        <th>Set Sub Task</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>
                                                            <input
                                                                className="form-control"
                                                                type="text"

                                                                placeholder="Enter Subtask Name"

                                                            />
                                                        </td>
                                                        <td className='width-80'>

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Remove Sub Task
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button className="table-action-btn" onClick={deleteModalShow}>
                                                                    <i className="fi fi-rr-trash text-exp-red"></i>
                                                                </button></OverlayTrigger>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                        <button type='button' className='btn btn-exp-primary btn-sm'>
                                            <i className="fi fi-br-plus me-2"></i>Add Sub Task
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="attachment-header">
                                        <h6>Attachments</h6>
                                        <div className="attachment-buttons">

                                            <Dropdown className="gth-modal-attachment-drpdown" align="end">
                                                <Dropdown.Toggle id="status" className="gth-action-button ms-2 ">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Add Attachments
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i class="fi fi-br-plus"></i>
                                                    </OverlayTrigger>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="attachment-wrap">
                                                    <div className="attachment-list">
                                                        <div className="attachment-list-item custom-file-upload">
                                                            <i class="fi fi-rr-clip me-2"></i>
                                                            <button class="upload-btn">Upload File</button>
                                                            <input type="file" name="myfile" className="input-upload" />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* for grid view */}
                                    <div className="card shadow-none border grid-files mt-2 mb-0">
                                        <div className="card-header bg-exp-primary-grey-light-1">
                                            <h6>Files</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="gth-attachment-body">
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="action-btn">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/login-bg-bk.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="gth-action-button">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/visitor.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    {/* for grid view end*/}

                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="description">
                                <h6>Description</h6>
                                <p className="mb-0 text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* calendar checksheet system controller modal end */}
            {/* calendar checksheet Doer modal start */}
            <form>
                <Modal id="calendar-checksheet-doer-modal" show={showChecksheetDoerModal} onHide={handleChecksheetDoerModalClose} backdrop="static" centered size="xl" className="task-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="task-title-edit-wrap">
                                <input value="Project 1 Sample View Task 2" className="task-name" />
                                <span className="task-edit"><i className="fi fi-rr-pencil"></i></span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="task-details-modal-body-wrap">
                        <div className="task-details-modal-body">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Assignee</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-green">
                                                        <span>JP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-user me-2"></i>
                                                <span>Doer</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-yellow">
                                                        <span>SP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-calendar me-2"></i>
                                                <span>Planned Date</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <span className="planned-date-txt">
                                                    {/* {planDate.toDateString()} */}
                                                    {format(startDate, "d MMMM, yyyy")}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i class="fi fi-rr-flag-alt me-2"></i>
                                                <span>Priority</span>
                                            </div>
                                        </div>
                                        <div className="col-8">

                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="priority-set urgent">
                                                    <i class="fi fi-sr-flag-alt mr-2"></i>
                                                    <span>Urgent</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                <span>Status</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <Dropdown className="gth-modal-dropdown">
                                                <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                    <div className="status-item inprogress">
                                                        <i className="fi fi-rr-process me-2"></i>
                                                        <span>In Progress</span>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="status-wrap">
                                                        <div className="status-list">
                                                            <div className="status-item todo status-list-item">
                                                                <i className="fi fi-rr-dot-circle me-2"></i>
                                                                <span>To Do</span>
                                                            </div>
                                                            <div className="status-item inprogress status-list-item">
                                                                <i className="fi fi-rr-process me-2"></i>
                                                                <span>In Progress</span>
                                                            </div>
                                                            <div className="status-item completed status-list-item" onClick={taskDoneModalShow}>
                                                                <i className="fi fi-rs-check-circle me-2"></i>
                                                                <span>Completed</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item">
                                                <span>Mode</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="status-item inprogress">
                                                    <span>Last Date of Every Month</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <div className="status-item todo">
                                                <i className="fi fi-rr-user me-2"></i>
                                                <span>Notify To <br />(if not done)</span>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-yellow">
                                                        <span>SP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow-none border mt-3 overflow-hidden">
                                <div className="card-header bg-exp-primary-grey-light-1 d-flex flex-wrap align-items-center justify-content-between">
                                    <h6 className="mb-0 me-3">Reminder</h6>
                                </div>

                                <div className="card-body pb-1">
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Reminder Mode</label>
                                                <div className='custom-select-wrap'>
                                                    <input type='text' value="Daily" className='form-control' readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Day Before </label>
                                                <input type='number' className='form-control' placeholder='Day Before' readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className='col-lg-12'>
                                <div className='card shadow-none border overflow-hidden bg-light-blue'>
                                    <div className='card-body p-0'>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Sub Task</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Enter Subtask Name"
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="attachment-header">
                                        <h6>Attachments</h6>
                                        <div className="attachment-buttons">

                                            <Dropdown className="gth-modal-attachment-drpdown" align="end">
                                                <Dropdown.Toggle id="status" className="gth-action-button ms-2 ">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Add Attachments
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i class="fi fi-br-plus"></i>
                                                    </OverlayTrigger>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="attachment-wrap">
                                                    <div className="attachment-list">
                                                        <div className="attachment-list-item custom-file-upload">
                                                            <i class="fi fi-rr-clip me-2"></i>
                                                            <button class="upload-btn">Upload File</button>
                                                            <input type="file" name="myfile" className="input-upload" />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* for grid view */}
                                    <div className="card shadow-none border grid-files mt-2 mb-0">
                                        <div className="card-header bg-exp-primary-grey-light-1">
                                            <h6>Files</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="gth-attachment-body">
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="action-btn">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/login-bg-bk.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="gth-action-button">
                                                                <i class="fi fi-br-menu-dots-vertical"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="attachment-image">
                                                        <SlideshowLightbox className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/visitor.jpg'} alt="attachment" />
                                                        </SlideshowLightbox>
                                                        <span className="image-expand-arrow"><i class="fi fi-rr-expand-arrows"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    {/* for grid view end*/}

                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="description">
                                <h6>Description</h6>
                                <p className="mb-0 text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                            <hr className="my-3" />
                            <div className="remarks">
                                <h6>Remarks</h6>
                                <p className="mb-0 text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                                <div className="remarks-attachment-wrap">
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-file-pdf"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Sample.pdf
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Sample.pdf
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-file-word"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.docx
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.docx
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-file-excel"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.xlsx
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.xlsx
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-picture"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.png
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.png
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                    <div className="remarks-attachment-item">
                                        <div className="icon">
                                            <i class="fi fi-rr-document"></i>
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    Lorem Ipsum has been the industry's standard.txt
                                                </Tooltip>
                                            }
                                        >
                                            <p className="text-truncate filename">
                                                Lorem Ipsum has been the industry's standard.txt
                                            </p>
                                        </OverlayTrigger>
                                        <button className="attachment-download action-btn">
                                            <i className="fi fi-sr-download"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* calendar checksheet Doer modal end */}
            {/* Calender Flow Submit System Controller Modal start */}
            <form>
                <Modal id="calendarFlowSubmitModal" show={flowSubmitModalSystemControllerShow} onHide={flowSubmitModalSystemControllerClose} backdrop="static" keyboard={false} centered size="lg" >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Check Product In Store</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Client Name</label>
                                    <p className='mb-0'>Siddha Group</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Client Contact</label>
                                    <p className='mb-0'>9123618202</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Product Description</label>
                                    <p className='mb-0'>Hand Towel</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Quantity</label>
                                    <p className='mb-0'>9</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Delivery Address</label>
                                    <p className='mb-0'>Vyom Project,New Alipore</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Delivery Date</label>
                                    <p className='mb-0'>2024-07-19</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 className="text-exp-blue">Product check in inventory for delivery.</h6>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Assign doer to Product Packing step</label>
                                    <Dropdown className="gth-modal-dropdown">
                                        <Dropdown.Toggle id="status" className="gth-transparent-btn w-100 p-0 text-start">
                                            <div className="form-select">
                                                {/* <div className="profile-wrap small-profile-wrap">
                                                        <div className="exp-avtar bg-exp-yellow">
                                                            <span>SP</span>
                                                        </div>
                                                        <div className="ps-2 profile-name-wrap text-truncate">
                                                            <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                        </div>
                                                    </div> */}
                                                <span className="text-muted">Select</span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="assignee-wrapper">
                                                <div className="search-assignee">
                                                    <span className="search-assignee-icon"><i class="fi fi-rr-search"></i></span><input type="text" className="form-control" placeholder="Search or enter email.." />
                                                </div>
                                                <div className="user-list-wrap">
                                                    <div className="user-item">
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar">
                                                                <i class="fi fi-rr-user user-icon"></i>
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-item">
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-green">
                                                                <span>JP</span>
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-item">
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar">
                                                                <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-item">
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar">
                                                                <i class="fi fi-rr-user user-icon"></i>
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-item">
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-blue">
                                                                <span>MS</span>
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap">Moumita Shome</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="user-item">
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar">
                                                                <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-truncate">Gopal Mukherjee</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 className="text-exp-blue">Assign TAT value for Product Packing</h6>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Tat Type</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='tatType'
                                            //options={tatType}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Tat Value</label>
                                    <input type="text" placeholder="Enter Tat Value" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Done
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* Calender Flow Submit System Controller Modal end */}
            {/* Calender Flow Submit Doer Modal start */}
            <form>
                <Modal id="calendarFlowSubmitDoerModal" show={flowSubmitDoerModalShow} onHide={flowSubmitDoerModalClose} backdrop="static" keyboard={false} centered size="lg" >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Check Product In Store</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Client Name</label>
                                    <p className='mb-0'>Siddha Group</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Client Contact</label>
                                    <p className='mb-0'>9123618202</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Product Description</label>
                                    <p className='mb-0'>Hand Towel</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Quantity</label>
                                    <p className='mb-0'>9</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Delivery Address</label>
                                    <p className='mb-0'>Vyom Project,New Alipore</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Delivery Date</label>
                                    <p className='mb-0'>2024-07-19</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 className="text-exp-blue">Product check in inventory for delivery.</h6>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Assign doer to Product Packing step</label>
                                    <div className="form-select cursor-not-allowed">
                                        <div className="profile-wrap small-profile-wrap">
                                            <div className="exp-avtar bg-exp-yellow">
                                                <span>SP</span>
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 className="text-exp-blue">Assign TAT value for Product Packing</h6>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Tat Type</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='tatType'
                                            isDisabled={true}
                                            //options={tatType}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Tat Value</label>
                                    <input type="text" placeholder="Enter Tat Value" className="form-control" readOnly />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className='form-label'>Remarks</label>
                                    <textarea type="text" placeholder="Enter Your Comments" rows="3" className="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Done
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* Calender Flow Submit Doer Modal end */}


            {/* Task Done modal start */}
            <form>
                <Modal id="calendarTaskDoneModal" show={taskDoneShow} onHide={taskDoneModalClose} backdrop="static" keyboard={false} centered >
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Complete Task of <span>Undefined</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label className="form-label">Remarks : </label>
                                    <textarea className="form-control" name="remarks" rows="3"></textarea>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label className="form-label">File : </label>
                                    <input className="form-control" type='file' />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* Task Done modal end */}
            {/* Delete modal start */}
            <form>
                <Modal id="calendar-delete-modal" show={deleteShow} onHide={deleteModalClose} backdrop="static" keyboard={false} centered >
                    <Modal.Header closeButton className="gth-light-red-bg">
                        <Modal.Title className="gth-text-danger">Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="delete-confirm-wrap text-center">
                            <div className="delete-confirm-icon mb-3 mt-2">
                                <img src={process.env.PUBLIC_URL + 'assets/images/delete-warning.svg'} alt="Warning" className="img-fluid" />
                            </div>
                            <h4 className="text-muted">Are you sure?</h4>
                            <p className="text-muted">
                                Do you really want to delete these record? This process cannot be undone.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <button className='btn btn-secondary' onClick={deleteModalClose}>
                            Cancel
                        </button>
                        <button className='btn btn-exp-red'>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
            {/* Delete modal end */}
        </React.Fragment>
    )
}

export default DashboardCalendar